import { NavLink } from 'react-router-dom';
import { IconType } from 'react-icons/lib';
import classNames from 'classnames';

import styles from './Sidebar.module.scss';


type SidebarItemProps = {
    text?: string;
    title?: string;
    href?: string;
    className?: string;
    Icon?: IconType;
    onClick?: () => void;
};

const SidebarItem: React.FC<SidebarItemProps> = ({ text, title, href, className, Icon, onClick }) => {
    const renderItem = () => {
        return (
            <div
                title={title || text}
                className={classNames(styles.item, className)}
                onClick={onClick}
            >
                {Icon && (
                    <div className={styles.icon}>
                        <Icon />
                    </div>
                )}
                {text && (
                    <div className={styles.text}>
                        {text}
                    </div>
                )}
            </div>
        );
    }

    return (
        <>
            {href ? (
                <NavLink to={href} className={({ isActive }) => classNames(isActive && styles.activeLink)}>
                    {renderItem()}
                </NavLink>
            ) : (
                renderItem()
            )}
        </>
    );
};

export default SidebarItem;