/* eslint-disable no-unused-vars */
export enum Environment {
    Development = 'Development',
    Staging = 'Staging',
    Integration = 'Integration',
    Production = 'Production'
}

export enum OrgUnitType {
    Region = "REG",
    Country = "CTY",
    Company = "CMP",

    BusinessArea = "BSA",
    Division = "DIV",
    ProductGroup = "PGR",
}

export enum ObjectType {
    Application = 'APP',

    Agreement = 'AGR',
    AgreementReport = 'ARR',
    Invoice = 'INV'
}
/* eslint-enable no-unused-vars */