import { Suspense, createElement, useMemo } from 'react';

import { useModalStore } from '@Stores';

import ModalSkeleton from './Components/ModalSkeleton/ModalSkeleton';


export const ModalContainer: React.FC = () => {
    const modalData = useModalStore(state => state.modalData);

    const getModalZIndex = (modalIndex: number) => 1050 + modalIndex * 50;

    const modals = useMemo(() => {
        return modalData.map((modal, index) => {
            const props = modal.props as React.Attributes;
            const element = createElement(modal.dialog, props);

            return (
                <div key={index} style={{ zIndex: getModalZIndex(index), position: 'fixed' }}>
                    <Suspense fallback={<ModalSkeleton {...props} />}>
                        {element}
                    </Suspense>
                </div>
            );
        });
    }, [modalData]);

    return (
        <>
            {modals}
        </>
    );
}
