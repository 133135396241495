import { useCallback } from 'react';

import { apiClient } from '@Models';

import { SinglePickerProps, SinglePicker } from '../Picker';


type LicenseeLocationPickerProps = SinglePickerProps & {
    loadDataParams?: {
        licenseeId?: number | null;
    }
}

export const LicenseeLocationPicker: React.FC<LicenseeLocationPickerProps> = (props) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadData = useCallback(apiClient.licenseeLocationLookupGet.bind(apiClient), []);

    return (
        <SinglePicker
            {...props}
            loadData={loadData}
        />
    );
}
