import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { AxiosPromise } from 'axios';

import { LookupStringValueModel } from '@Models';
import { useDebounce, useMountEffect } from '@Hooks';

import { FormFeedback } from '../Basic/FormFeedback/FormFeedback';
import { Select } from '../Basic/Select/Select';

type BaseAutocompletePickerProps = {
    disabled?: boolean;
    isClearable?: boolean;
    className?: string;
    debounceTime?: number;
    invalid?: boolean;
    validationErrors?: string[];
    onGetCustomRequestParams?: () => object;
};

export type AutocompleteStringPickerProps = BaseAutocompletePickerProps & {
    value?: string | null;
    onChange?: (option: LookupStringValueModel | null) => Promise<void> | void;
    loadData?: (params?: object) => AxiosPromise<LookupStringValueModel[]>;
};

export const AutocompleteStringPicker: React.FC<AutocompleteStringPickerProps> = ({
    value, debounceTime, disabled, className, validationErrors,
    onGetCustomRequestParams, onChange, loadData, ...props
}) => {
    const [options, setOptions] = useState<LookupStringValueModel[]>([]);
    const [inputValue, setInputValue] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);

    const debouncedInputValue = useDebounce(inputValue, debounceTime);

    useMountEffect(() => {      
        loadOptions();
    });

    const getRequestParams = useCallback((inputValue?: string) => {
        const params: object & { term?: string } = onGetCustomRequestParams ? onGetCustomRequestParams() : {};
        if (inputValue) params.term = inputValue;

        return params;
    }, [onGetCustomRequestParams]);

    const loadOptions = useCallback(async (inputValue?: string) => {
        if (!loadData) return;

        setIsLoading(true);

        try {
            const { data } = await loadData(getRequestParams(inputValue));
            setOptions(data);
        } finally {
            setIsLoading(false);
        }
    }, [getRequestParams, loadData]);

    useEffect(() => {
        loadOptions(debouncedInputValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedInputValue, loadOptions]);

    const handleChange = (value: LookupStringValueModel | null) => {
        onChange?.(value);
    }

    const handleInputChange = (newValue?: string) => {
        setInputValue(newValue);   
    }

    const selectedValue = useMemo(() => {
        return options.find(option => option.value === value);
    }, [options, value]);

    return (
        <>
            <Select<LookupStringValueModel>
                options={options}
                value={selectedValue}
                isDisabled={disabled}
                classNamePrefix={className}
                isLoading={isLoading}
                inputValue={inputValue}
                onChange={handleChange}
                onInputChange={handleInputChange}
                {...props}
            />
            {!!validationErrors?.length && (
                <FormFeedback>
                    {validationErrors.join(' ')}
                </FormFeedback>
            )}
        </>
    );
}
