import { useState } from 'react';
import { AiOutlineMenu } from 'react-icons/ai';
import { FiLogOut } from 'react-icons/fi';

import { getCurrentUser, getImpersonatedPrincipal, logout, useAuthStore } from '@Stores';

import { IconButton } from '@Components';
import Sidebar from '../Sidebar/Sidebar';
import { ImpersonationButtons } from './Components/ImpersonationButtons/ImpersonationButtons';
import AppThemeButton from './Components/AppThemeButton/AppThemeButton';
import styles from './Header.module.scss';
import Logo from './Components/Logo/Logo';


const Header = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const appSecurityModel = useAuthStore(state => state.appSecurityModel);

    const handleMenuButtonClick = () => {
        setIsSidebarOpen(!isSidebarOpen);
    }

    const closeSidebar = () => {
        setIsSidebarOpen(false);
    }

    const displayName = getImpersonatedPrincipal()?.displayName || getCurrentUser()?.name || '';

    return (
        <>
            <Sidebar
                isOpen={isSidebarOpen}
                close={closeSidebar}
            />

            <div className={styles.header}>
                <div className={styles.leftSide}>
                    {appSecurityModel?.hasPrincipalAnyPermission &&
                        <IconButton
                            title='Show menu'
                            className={styles.menuButton}
                            onClick={handleMenuButtonClick}
                            IconType={AiOutlineMenu}
                        />
                    }

                    <Logo />
                </div>
                <div className={styles.rightSide}>
                    <div className={styles.userName}>{displayName}</div>
                    <AppThemeButton
                        className={styles.button}
                    />
                    <ImpersonationButtons
                        className={styles.button}
                    />
                    <IconButton
                        title="Logout"
                        className={styles.button}
                        onClick={logout}
                        IconType={FiLogOut}
                    />
                </div>
            </div>
        </>
    );
};

export default Header;
