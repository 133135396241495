import { ReactNode } from 'react';

import styles from './Layout.module.scss';
import Header from './Components/Header/Header';
import { Breadcrumbs } from './Components/Breadcrumbs/Breadcrumbs';

type LayoutProps = {
    children: ReactNode;
};

export const Layout: React.FC<LayoutProps> = ({ children }) => {
    return (
        <>
            <Header />
            <Breadcrumbs />
            <div className={styles.layout}>
                {children}
            </div>
        </>
    );
};
