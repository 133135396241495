import { useCallback } from "react";

import { apiClient } from "@Models";

import { SinglePickerProps, SinglePicker } from "../Picker";

export const RolePicker: React.FC<SinglePickerProps> = (props) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadData = useCallback(apiClient.securityTabsSearchRolesGet.bind(apiClient), []);

    return (
        <SinglePicker
            {...props}
            loadData={loadData}
        />
    );
}
