
import { Link } from 'react-router-dom';
import { BiSolidChevronRight } from 'react-icons/bi';
import classNames from 'classnames';

import styles from './BreadcrumbsItem.module.scss';

type BreadcrumbsItemProps = {
    title: string;
    href: string;
    isLast?: boolean;
};

const BreadcrumbsItem: React.FC<BreadcrumbsItemProps> = ({ title, href, isLast }) => {
    const renderTitle = () => <div className={styles.title}>{title}</div>;

    return (
        <div
            className={classNames(styles.item, isLast && styles.last)}
        >
            {isLast ? (
                renderTitle()
            ) : (
                <Link to={href}>
                    {renderTitle()}
                </Link>
            )}
            <BiSolidChevronRight className={styles.icon} />
        </div>
    );
};

export default BreadcrumbsItem;
