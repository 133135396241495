import { useCallback } from "react";

import { apiClient } from "@Models";

import { SinglePickerProps, SinglePicker } from "../Picker";

type LicenseeCreationAvailableDivisionPickerProps = SinglePickerProps & {
}

export const LicenseeCreationAvailableDivisionPicker: React.FC<LicenseeCreationAvailableDivisionPickerProps> = (props) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadData = useCallback(apiClient.licenseeLicenseeCreationAvailableDivisionsLookupGet.bind(apiClient), []);

    return (
        <SinglePicker
            {...props}
            loadData={loadData}
        />
    );
}
