import { memo } from 'react';
import classNames from 'classnames';

import styles from './TabItem.module.scss';

type TabItemProps = {
    title: string;
    isActive?: boolean;
    onClick?: () => void;
};

export const TabItem: React.FC<TabItemProps> = memo(({ title, isActive, onClick }) => {

    return (
        <div
            className={classNames(styles.tabItem, isActive && styles.active)}
            onClick={onClick}
        >
            {title}
        </div>
    );
});
