import React from 'react';
import classNames from 'classnames';

import styles from './Checkbox.module.scss';

type CheckboxProps = React.InputHTMLAttributes<HTMLInputElement> & {
	invalid?: boolean;
	innerRef?: React.Ref<HTMLInputElement>;
	isIndeterminate?: boolean;
};

export const Checkbox: React.FC<CheckboxProps> = ({ innerRef, className, invalid, isIndeterminate, ...rest }) => {

	return (
		<label className={classNames(styles.wrapper, invalid && styles.invalid, isIndeterminate && styles.indeterminate, className)}>
			<input {...rest} type="checkbox" ref={innerRef} />
			<div className={styles.checkmark}></div>
			<div className={styles.indeterminateBlock} />
		</label>
	);
}
