import { ButtonType, Modal, ModalButton } from '@Components';


export type ConfirmationDialogProps = {
    title?: string;
    message?: string | JSX.Element;
    onConfirm: () => void;
    onClose: () => void;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({ title, message, onConfirm, onClose }) => {
    const handleConfirm = () => {
        onConfirm();
    }

    const handleCancel = () => {
        onClose();
    }

    const getButtons = () => {
        const buttons: ModalButton[] = [];

        buttons.push({ name: 'Confirm', type: ButtonType.Success, onClick: handleConfirm });
        buttons.push({ name: 'Cancel', type: ButtonType.Danger, onClick: handleCancel });

        return buttons;
    }

    return (
        <Modal
            isOpen
            title={title || 'Confirmation'}
            buttons={getButtons()}
            onClose={onClose}
        >
            {message || 'Are you sure?'}
        </Modal>
    );
}
