export class PrincipalConfig {
    public static readonly GridStateKey: string = 'GridState';

    public static readonly ReportsOverview: string = 'ReportsOverview';
    public static readonly Divisions: string = 'Divisions';
    public static readonly Locations: string = 'Locations';
    public static readonly Principals: string = 'Principals';
    public static readonly MessagingSchedules: string = 'MessagingSchedules';
    public static readonly Attachments: string = 'Attachments';
    public static readonly AbacusAttachments: string = 'AbacusAttachments';
    public static readonly ChangeLog: string = 'ChangeLog';
    public static readonly Configuration: string = 'Configuration';
    public static readonly Licensees: string = 'Licensees';
    public static readonly Licensors: string = 'Licensors';
    public static readonly AgreementReports: string = 'AgreementReports';
    public static readonly PONumberHistory: string = 'PONumberHistory';
    public static readonly SubPG: string = 'SubPG';
    public static readonly MyTasks: string = 'MyTasks';
    public static readonly InvoiceItems: string = 'InvoiceItems';
    public static readonly Invoicing: string = 'Invoicing';
    public static readonly Reporting: string = 'Reporting';
    public static readonly Agreements: string = 'Agreements';
    public static readonly ReportChargeableItems: string = 'ReportChargeableItems'; 
    public static readonly ChargeableItems: string = 'ChargeableItems'; 
}
