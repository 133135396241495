const DEFAULT_MAX_LENGTH = 16;

type NumberFormatOptions = {
    maxLength?: number;
    maxDecimalLength?: number;
    isNegativeValueDisabled?: boolean;
	isMaxLengthDisabled?: boolean;
	isDecimalDisabled?: boolean;
}

export const toNumberFormat = (value: string, options?: NumberFormatOptions) => {
    const inputValues = value.split('');
    const validValues = [];
    let decimalLength = 0;
    let hasDotOrComma = false;
    let isDecimal = false;

    for (let i = 0; i < inputValues.length; i++) {
        const char = inputValues[i];
        const isDotOrComma = char.includes(',') || char.includes('.');

        if (!options?.isNegativeValueDisabled && i === 0 && char.includes('-')) {
            validValues.push(char);
            continue;
        }

        if (isDotOrComma) {
            if (hasDotOrComma) continue;

            if (!(options?.isDecimalDisabled)) {
                validValues.push('.');
                hasDotOrComma = true;
            }
            isDecimal = true;
        } else {
            if ((isNaN(Number(char)) || char.includes(' '))) continue;

            if (isDecimal && options?.isDecimalDisabled) continue;

            if (!(options?.isMaxLengthDisabled) && !isDecimal) {
                if (validValues.length < (options?.maxLength || DEFAULT_MAX_LENGTH)) {
                    validValues.push(char);
                }
                continue;
            }

            if (isDecimal) {
                if (options?.maxDecimalLength && decimalLength >= options.maxDecimalLength) {
                    continue;
                }

                decimalLength += 1;
            }
            
            validValues.push(char);
        }
    }

    return validValues.join('');
}
