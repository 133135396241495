import { memo } from 'react';
import classNames from 'classnames';
import { AiFillCheckCircle, AiFillCloseCircle, AiFillExclamationCircle, AiFillWarning } from 'react-icons/ai';
import { PiWarningFill } from 'react-icons/pi';

import styles from './TextPanel.module.scss';


export enum TextPanelType {
    Info,
    Success,
    Warning,
    Error
}

type TextPanelProps = {
    type: TextPanelType;
    text: string;
    className?: string;
}

export const TextPanel: React.FC<TextPanelProps> = memo(({ type, text, className }) => {
    const getIcon = () => {
        switch (type) {
            case TextPanelType.Info: 
                return <AiFillExclamationCircle />;
            case TextPanelType.Success: 
                return <AiFillCheckCircle />;
            case TextPanelType.Warning: 
                return <AiFillWarning />;
            case TextPanelType.Error: 
                return <AiFillCloseCircle />;
            default:
                return <PiWarningFill />;
        }
    };

    const getTypeClassName = () => {
        switch (type) {
            case TextPanelType.Info: 
                return styles.info;
            case TextPanelType.Success: 
                return styles.success;
            case TextPanelType.Warning: 
                return styles.warning;
            case TextPanelType.Error: 
                return styles.error;
            default:
                return;
        }
    };

    return (
        <div 
            className={classNames(
                styles.container,
                className,
                getTypeClassName()
            )}
        >
            <div className={styles.icon}>
                {getIcon()}
            </div>
            <div className={styles.content}>
                {text}
            </div>
        </div>
    );
});
