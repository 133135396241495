import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';
import { Placement } from '@popperjs/core';


type ToolTipProps = {
	target: string | HTMLElement | React.RefObject<HTMLElement>;
    isOpen?: boolean;
	className?: string;
    innerClassName?: string;
	placement?: Placement;
    autohide?: boolean;
    children?: React.ReactNode;
};

export const ToolTipItem: React.FC<ToolTipProps> = ({
    target, className, placement, autohide, innerClassName, children, ...props
}) => {
    const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);

    const handleToggle = () => setIsTooltipOpen(!isTooltipOpen);

    const isControlledTooltip = typeof props.isOpen !== 'undefined';
    const isOpen = isControlledTooltip ? props.isOpen : isTooltipOpen;

    return (
        <Tooltip
            target={target}
            isOpen={isOpen}
            className={className}
            innerClassName={innerClassName}
            placement={placement ? placement : 'auto'}
            autohide={autohide}
            toggle={handleToggle}
        >
            {children}
        </Tooltip>
    );
};
