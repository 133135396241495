import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import { useAuthStore } from '@Stores';
import { requestTimelineService, signalRService } from '@Services';
import { useMountEffect, useAppTheme } from '@Hooks';

import { ErrorBoundary, Layout, Loading, ModalContainer, NotificationHandler, NotificationContainer } from '@Components';
import './Scss/App.scss';


const Dashboard = React.lazy(() => import('@Pages/Dashboard/Dashboard'));
const NoAccess = React.lazy(() => import('@Pages/NoAccess/NoAccess'));
const Agreements = React.lazy(() => import('@Pages/Agreements/Agreements'));
const AgreementDetails = React.lazy(() => import('@Pages/AgreementDetails/AgreementDetails'));
const Reporting = React.lazy(() => import('@Pages/Reporting/Reporting'));
const ReportDetails = React.lazy(() => import('@Pages/ReportDetails/ReportDetails'));
const AbacusData = React.lazy(() => import('@Pages/AbacusData/AbacusData'));
const Invoicing = React.lazy(() => import('@Pages/Invoicing/Invoicing'));
const InvoiceDetails = React.lazy(() => import('@Pages/InvoiceDetails/InvoiceDetails'));
const Administration = React.lazy(() => import('@Pages/Administration/Administration'));
const NotFound = React.lazy(() => import('@Pages/NotFound/NotFound'));

const App = () => {
    const appSecurityModel = useAuthStore(state => state.appSecurityModel);
    const loadAppSecurityModel = useAuthStore(state => state.loadAppSecurityModel);

    useAppTheme();

    useMountEffect(() => {
        signalRService.initialize();

        NotificationHandler.subscribeToNotifications();
        requestTimelineService.init();
    });

    useEffect(() => { loadAppSecurityModel() }, [loadAppSecurityModel]);

    if (!appSecurityModel) return <Loading fillContainer />;

    const canReadAdministration = appSecurityModel.hasPrincipalApplicationAdministrationRead || appSecurityModel.hasPrincipalAnyLicensorDivisionRead || appSecurityModel.hasPrincipalAnyCreateLicensee;

    return (
        <ErrorBoundary>
            <NotificationContainer />
            <ModalContainer />
            <Layout>
                <React.Suspense fallback={<Loading fillContainer />}>
                    <Routes>
                        <Route path="/">
                            {!appSecurityModel.hasPrincipalAnyPermission &&
                                <Route path="*" element={<NoAccess />} />
                            }

                            {appSecurityModel.hasPrincipalAnyPermission &&
                                <Route index element={<Dashboard />} />
                            }
                            {!appSecurityModel.hasPrincipalAnyPermission &&
                                <Route index element={<NoAccess />} />
                            }

                            {appSecurityModel.hasPrincipalAnyPermission &&
                                < Route path="agreements">
                                    <Route index element={<Agreements />} />
                                    <Route path=":agreementId/*" element={<AgreementDetails />} />
                                </Route>
                            }

                            {appSecurityModel.hasPrincipalAnyPermission &&
                                <Route path="reporting">
                                    <Route index element={<Reporting />} />
                                    <Route path=":reportId/*" element={<ReportDetails />} />
                                </Route>
                            }

                            {appSecurityModel.hasPrincipalManageAbacusAttachment &&
                                <Route path="abacus-data/*" element={<AbacusData />} />
                            }

                            {appSecurityModel.hasPrincipalReadInvoice && (
                                <Route path="invoicing">
                                    <Route index element={<Invoicing />} />
                                    <Route path=":invoiceId/*" element={<InvoiceDetails />} />
                                </Route>
                            )}

                            {canReadAdministration &&
                                <Route path="administration/*" element={<Administration appSecurityModel={appSecurityModel} />} />
                            }

                            {appSecurityModel.hasPrincipalAnyPermission &&
                                <Route path="*" element={<NotFound />} />
                            }
                        </Route>
                    </Routes>
                </React.Suspense>
            </Layout>
        </ErrorBoundary>
    );
}

export default App;
