import * as React from 'react';
import DateTimeField from '@1stquad/react-bootstrap-datetimepicker';
import { ImCross } from 'react-icons/im';
import classNames from 'classnames';

import { DateTime } from '@AppConstants';
import { DateTimeService } from '@Services';

import { FormFeedback } from '@Components';
import styles from './DatePicker.module.scss';


export type DatePickerProps = {
    wrapperClassName?: string;
    disabled?: boolean;
    invalid?: boolean;
    showToday?: boolean;
	validationErrors?: string[];

    minDate?: Date;
    maxDate?: Date;
    value?: Date;
    availableDates?: Date[];

    viewMode?: string;
    pickerMode?: string;
    pickerSize?: string;

    daysOfWeekDisabled?: number[];
    allDaysDisabled?: boolean;

    onBlur?: Function;
    onEnter?: Function;
    onChange?: (date: Date, value: string) => void;
    onClear?: () => void;
};

export const DatePicker: React.FC<DatePickerProps> = (props) => {
    const { disabled, minDate, maxDate, onBlur, onEnter, pickerMode, pickerSize,
        daysOfWeekDisabled, viewMode, availableDates, validationErrors,
        value, wrapperClassName, showToday, invalid, onChange, onClear } = props;

    const handleChange = (date: Date, value: string) => {
        const d = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()))

        onChange?.(d, value);
    }

    const getPickerFormat = (mode?: string): string => {
        if (mode === 'datetime') {
            return DateTime.viewFullFormat;
        }
        if (mode === 'time') {
            return DateTime.timeFormat;
        }
        return DateTime.viewDateFormat;
    }

    const renderFeedback = () => {
		if (!validationErrors?.length) return null;

		return (
			<FormFeedback>
				{validationErrors.join(' ')}
			</FormFeedback>
		)
	}

    const getValidatedValue = () => {
        if (!value) return '';
        if (!DateTimeService.isValidDate(value)) return value;

        return DateTimeService.parse(DateTimeService.format(value, format, format !== DateTime.viewDateFormat), format, format !== DateTime.viewDateFormat);
    }

    const minimalDate = minDate && DateTimeService.isValidDate(minDate) ? minDate : DateTimeService.parse(DateTime.minDate, DateTime.viewDateFormat);
    const maximalDate = maxDate && DateTimeService.isValidDate(maxDate) ? maxDate : undefined;

    const format = getPickerFormat(pickerMode);

    return (
        <>
            <div className={classNames(styles.datePicker, invalid && styles.invalid, showToday === false && styles.showTodayDisabled, wrapperClassName)}>
                <DateTimeField
                    dateTime={getValidatedValue()}
                    hideInputIcon
                    inputFormat={format}
                    onChange={handleChange}
                    onBlur={onBlur ? onBlur : undefined}
                    onEnterKeyDown={onEnter ? onEnter : undefined}
                    mode={pickerMode ? pickerMode : 'date'}
                    viewMode={viewMode ? viewMode : 'date'}
                    size={pickerSize ? pickerSize : 'sm'}
                    minDate={minimalDate}
                    maxDate={maximalDate}
                    disabled={disabled}
                    daysOfWeekDisabled={daysOfWeekDisabled ? daysOfWeekDisabled : []}
                    availableDates={availableDates}
                />
                {!disabled && onClear && value && <div className={styles.clearIcon} onClick={onClear}>
                    <ImCross /> 
                </div>}
            </div>

            {renderFeedback()}
        </>
    );
}