import { memo, useRef, useState } from 'react';
import classNames from 'classnames';
import { AiOutlineInfoCircle } from 'react-icons/ai';

import { ToolTipItem } from '@Components';

import styles from './LabelWithDescription.module.scss';


type LabelWithDescriptionProps = {
    text?: string;
    description?: string | JSX.Element;
    className?: string;
    required?: boolean;
};

export const LabelWithDescription: React.FC<LabelWithDescriptionProps> = memo(({ text, description, required, className }) => {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    const iconRef = useRef(null);

    const handleMouseEnter = () => {
        setIsTooltipOpen(true);
    }

    const handleMouseLeave = () => {
        setIsTooltipOpen(false);
    }

    return (
        <div className={classNames(styles.label, className)}>
            <div className={styles.text}>
                {text}{required ? <span>*</span> : ''}

                {description && (
                    <div
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <div
                            ref={iconRef}
                            className={styles.icon}
                        >
                            <AiOutlineInfoCircle />
                        </div>

                        <ToolTipItem
                            target={iconRef}
                            isOpen={isTooltipOpen}
                            autohide={false}
                            className={styles.tooltip}
                        >
                            {description}
                        </ToolTipItem>
                    </div>
                )}
            </div>
        </div>
    );
});
