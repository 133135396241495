import { useState, useRef } from 'react';
import { BiFileBlank } from 'react-icons/bi';

import styles from './DragNDropWrapper.module.scss';
import classNames from 'classnames';

type DragNDropWrapperProps = {
    dragText?: string; 
    disabled?: boolean;
    wrapperClassName?: string;
    children: React.ReactNode;
    onFilesDrop: (files: FileList) => void;
};

export const DragNDropWrapper: React.FC<DragNDropWrapperProps> = ({
    dragText, disabled, wrapperClassName, children, onFilesDrop
}) => {
    const [isDragActive, setIsDragActive] = useState(false);
    const dragCounter = useRef(0);

    const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        dragCounter.current = ++dragCounter.current;
        setIsDragActive(true);
    }

    const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        const counter = --dragCounter.current;

        dragCounter.current = counter;
        if (counter > 0) return;

        setIsDragActive(false);
    }

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
    }

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setIsDragActive(false);

        if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
            onFilesDrop(event.dataTransfer.files);

            event.dataTransfer.clearData();
            dragCounter.current = 0;
        }
    }

    if (disabled) return <>{children}</>;

    return (
        <div
            className={classNames(styles.wrapper, wrapperClassName)}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
        >
            {isDragActive && (
                <div className={styles.dragContent}>
                    <BiFileBlank className={styles.dragIcon} />
                    <div className={styles.dragText}>{dragText || 'Upload Files'}</div>
                </div>
            )}
            {children}
        </div>
    );
}
