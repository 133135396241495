import { memo } from 'react';
import { toNumberFormat } from './numberInputHelpers';
import { FormFeedback } from '../FormFeedback/FormFeedback';
import { TextArea, TextAreaProps } from '../TextArea/TextArea';


export type NumberTextareaProps = Omit<TextAreaProps, 'onChange'> & {
	maxLength?: number;
	maxDecimalLength?: number;
	isNegativeValueDisabled?: boolean;
	isMaxLengthDisabled?: boolean;
	isDecimalDisabled?: boolean;
	isSelectedOnFocus?: boolean;
	onChange: (value: string) => void;
};

/** For specific cases */
export const NumberTextarea = memo(({
	isNegativeValueDisabled, maxDecimalLength, isMaxLengthDisabled, isDecimalDisabled,
	maxLength, validationErrors, isSelectedOnFocus, onChange, ...props
}: NumberTextareaProps) => {

	const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		const numberValue = event.currentTarget?.value ? toNumberFormat(String(event.currentTarget.value), {
			isDecimalDisabled, maxLength, isMaxLengthDisabled, isNegativeValueDisabled, maxDecimalLength
		}) : '';

		onChange(numberValue);
	}

	const handleFocus = (event: React.FocusEvent<HTMLTextAreaElement>) => {
		props.onFocus?.(event);

		setTimeout(() => {
			if (isSelectedOnFocus) event.target.select();
		}, 0);
	}

	return (
		<>
			<TextArea
                {...props}
                rows={1}
                onChange={handleChange}
				onFocus={handleFocus}
            />
			{!!validationErrors?.length && (
				<FormFeedback>
					{validationErrors.join(' ')}
				</FormFeedback>
			)}
		</>
	);
});

