import { getLocalStorageValue } from "@Helpers";
import { UserProfile } from "@Models";

export interface IWindowAdal extends Window {
    adal?: {
        redirectToLogin(): void;
        redirectToLogout(): void;
    };
}

export interface IImpersonationModel {
    principalId?: number;
    displayName?: string;
    email?: string;
    objectId?: string;
    roles?: Array<string>;
}

export const clearUserData = () => {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentToken');
    localStorage.removeItem('impersonatedUser');
};

export const login = () => {
    clearUserData();
    const appWindow: IWindowAdal = window;
    appWindow.adal && appWindow.adal.redirectToLogin();
};

export const logout = () => {
    clearUserData();
    const appWindow: IWindowAdal = window;
    appWindow.adal && appWindow.adal.redirectToLogout();
};

export const getCurrentToken = () => (getLocalStorageValue<string>('currentToken') || '').replace(/"/gi, '');

export const getCurrentUser = () => getLocalStorageValue<UserProfile>('currentUser');

export const getImpersonatedPrincipal = (): IImpersonationModel | null => getLocalStorageValue<IImpersonationModel>('impersonatedPrincipal');

export const getIsAuthorized = () => !!getCurrentToken() && !!getCurrentUser();
