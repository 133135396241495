import { memo } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import styles from './NavTabItem.module.scss';

type NavTabItemProps = {
    title: string;
    href: string;
    isExact?: boolean;
};

export const NavTabItem: React.FC<NavTabItemProps> = memo(({ title, href, isExact }) => {

    return (
        <NavLink
            end={isExact}
            to={href}
            className={({ isActive }) => classNames(styles.tabItem, isActive && styles.active)}
        >
            {title}
        </NavLink>
    );
});
