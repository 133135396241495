import TreeViewNode from '../TreeViewNode/TreeViewNode';
import { TreeNode } from '../../TreeView';


type TreeViewListProps = {
    nodes: TreeNode[];
    selectedNodeIds: string[];
    expandedNodeIds: string[];
    expandedAllNodes?: boolean;
    canSelectParentNode?: boolean;
    className?: string;
    onNodeSelect?: (id: string) => void;
    onNodeDeselect?: (id: string) => void;
    onNodeExpand: (id: string) => void;
    onNodeCollapse: (id: string) => void;
    onChildrenLoad?: (id: string) => Promise<void>;
};

const TreeViewList: React.FC<TreeViewListProps> = ({
    nodes, selectedNodeIds, expandedNodeIds, expandedAllNodes, className, canSelectParentNode,
    onNodeSelect, onNodeDeselect, onNodeExpand, onNodeCollapse, onChildrenLoad
}) => {

    return (
        <div className={className}>
            {nodes?.map(node => (
                <TreeViewNode
                    key={node.id}
                    node={node}
                    selectedNodeIds={selectedNodeIds}
                    expandedNodeIds={expandedNodeIds}
                    expandedAllNodes={expandedAllNodes}
                    canSelectParentNode={canSelectParentNode}
                    onNodeSelect={onNodeSelect}
                    onNodeDeselect={onNodeDeselect}
                    onExpand={onNodeExpand}
                    onCollapse={onNodeCollapse}
                    onChildrenLoad={onChildrenLoad}
                />
            ))}
        </div>
    );
}

export default TreeViewList;
