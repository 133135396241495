import { NotificationHandler } from '@Components';
import { ResponseError } from './ApiService';

type ResponseData = {
    error?: string;
}

class ErrorHandleService {
    private _lastError: { message: string, time: number };

    async showError(url: string, error: ResponseError<ResponseData>) {
        const currentTime = (new Date()).getTime();
        console.log('--error AJAX: ', error);
        if (!this._lastError || this._lastError.message !== error.message || (currentTime - this._lastError.time > 10000)) {
            if (error.response && error.response.status !== 403) {
                const notificationTitle = error.response.statusText;
                const notificationMessage = error.response.data.error || error.message;

                NotificationHandler.showError(notificationMessage, notificationTitle);

                this._lastError = { message: error.message, time: currentTime };
            }
        }
    }
}

export const errorHandleService = new ErrorHandleService();