import React from 'react';
import classNames from 'classnames';

import styles from './FormFeedback.module.scss';

type FormFeedbackProps = {
    className?: string;
    children: React.ReactNode;
};

export const FormFeedback: React.FC<FormFeedbackProps> = ({ className, children }) => {
	return (
        <div className={classNames(styles.feedback, className)}>
            {children}
        </div>
    )
}
