import { SignalREvents } from "@AppConstants";
import { signalRService } from "./SignalRService";

type JobTimelineModel = {
    jobName: string;
    triggerId: string;
    rawTimeline: string;
};

class RequestTimelineService {
    private static _instance: RequestTimelineService;
    private _bc: BroadcastChannel | null = null;

    constructor() {
        if (this.isSupported) {
            this._bc = new BroadcastChannel('request-timeline-channel');
        }

        if (RequestTimelineService._instance) {
            throw new Error(`You can't initialize several instances. Use getInstance instead`);
        }
    }

    public get isSupported() {
        return typeof BroadcastChannel !== 'undefined';
    }

    public static getInstanse(): RequestTimelineService {
        if (!RequestTimelineService._instance) {
            RequestTimelineService._instance = new RequestTimelineService();
        }

        return RequestTimelineService._instance;
    }

    public init() {
        signalRService.subscribe<JobTimelineModel>(SignalREvents.sendJobTimeline, (_, eventData) => {
            this.addTimeline(eventData.rawTimeline);
        });
    }

    public addTimeline(rawData: string) {
        this._bc?.postMessage(rawData);
    }
}

export const requestTimelineService = RequestTimelineService.getInstanse();