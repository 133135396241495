import { create } from 'zustand';
import { AppSecurityModel, apiClient } from '@Models';

type AuthStore = {
    appSecurityModel: AppSecurityModel | null;
    loadAppSecurityModel: () => Promise<void>;
}

export const useAuthStore = create<AuthStore>(set => ({
    appSecurityModel: null,

    loadAppSecurityModel: async () => {
        const { data } = await apiClient.securityAppSettingsGet();

        set({ appSecurityModel: data });
    }
}))
