const NUMBER_INACCURACY = 0.000001;

type NumberFormatOptions = {
    decimalsNumber?: number;
    thousandsSeparator?: string;
    decimalSeparator?: string;
}

export const formatNumber = (value: number, options?: NumberFormatOptions) => {
    let decimalsNumber = options?.decimalsNumber;

    if (typeof decimalsNumber === 'undefined') decimalsNumber = getDecimalsNumber(value);
    
    // Get number and decimal part of value
    const valueString = Number(value + NUMBER_INACCURACY).toFixed(decimalsNumber);

    return formatNumberWithSeparators(valueString, options?.thousandsSeparator, options?.decimalSeparator);
}

export const formatNumberWithSeparators = (valueString: string, thousandsSeparator?: string, decimalSeparator?: string) => {
    if (typeof thousandsSeparator === 'undefined') thousandsSeparator = `'`;
    if (typeof decimalSeparator === 'undefined') decimalSeparator = `,`;

    const valueParts = valueString.split('.');

    const isNegative = valueParts[0].startsWith('-');
    const intPart = isNegative ? valueParts[0].substring(1, valueParts[0].length) : valueParts[0];
    const decPart = valueParts[1];

    const resultParts = [];
    // Add thousands separator and decimal point (if required):
    if (intPart.length <= 3) {
        resultParts.push(intPart);
    } else {
        for (let iLen = intPart.length, i = iLen ? iLen % 3 || 3 : 0, j = 0; i <= iLen; i += 3) {
            resultParts.push(intPart.substring(j, i));
            j = i;
        }
    }

    return (isNegative ? '-' : '') + resultParts.join(thousandsSeparator) + (decPart ? decimalSeparator + decPart : '');
}

export const getDecimalsNumber = (value: number) => {
    if (Math.floor(value) === value) return 0;

    return value.toString().split(".")[1].length || 0;
}

export const toUINumber = (value: number, decimalsNumber?: number) => formatNumber(value, { decimalsNumber });
