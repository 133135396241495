import { useState, useEffect, useRef, useMemo } from 'react';

import { FormFeedback } from '../FormControls';
import styles from './FileUploader.module.scss';

export type FileUploaderProps = {
    value: File | null;
    id?: string;
    disabled?: boolean;
    acceptedFormats?: string;
    maxFileSizeInMb?: number;
    validationErrors?: string[];
    onChange: (file: File | null) => void;
};

export const FileUploader: React.FC<FileUploaderProps> = ({
    value, disabled, acceptedFormats, id, maxFileSizeInMb, validationErrors, onChange
}) => {
    const [error, setError] = useState<string | null>(null);
    const [fileName, setFileName] = useState<string | null>(null);

    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (!value) return;

        if (!validateFile(value)) onChange(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const maxFileSize: number = 1024 * 1024 * (maxFileSizeInMb || 30);

    const validateFile = (file: File) => {
        setFileName(file.name);

        if (file.size > maxFileSize || (acceptedFormats && !acceptedFormats.includes(file.type))) {
            setError(`Wrong file format or size > ${maxFileSizeInMb ?? 30}MB`);

            onChange(null);
            return false;
        }

        setError(null);
        return true;
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        const files = Array.from(event.target.files);

        if (files && files.length) {
            const file = files[0];

            if (validateFile(file)) {
                onChange(file);
            }
        }
    };

    const handleFileInputClick = () => {
        inputRef?.current?.click();
    }

    const errors = useMemo(() => {
        const errors = validationErrors ? [...validationErrors] : [];

        if (error) errors.push(error);

        return errors;
    }, [error, validationErrors]);

    return (
        <>
            <div className={styles.fileInput} onClick={handleFileInputClick}>
                <div className={styles.chooseFile}>Choose File</div>
                <div className={styles.fileName}>{fileName || 'No file choosen'}</div>
            </div>
            {!!errors.length && (
                <FormFeedback>
                    {errors.join(' ')}
                </FormFeedback>
            )}

            <input
                ref={inputRef}
                id={id || "fileUpload"}
                type="file"
                className={styles.input}
                accept={acceptedFormats}
                disabled={disabled}
                onChange={handleFileChange}
            />
        </>
    );
}
