import { RefObject, useCallback, useEffect, useRef, useState } from "react";

// eslint-disable-next-line react-hooks/exhaustive-deps
export const useMountEffect = (cb: React.EffectCallback) => useEffect(cb, []);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function usePrevious<T>(value: T) {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export const useOnClickOutside = (ref: RefObject<HTMLElement>, handler: () => void) => {
    const handleClick = useCallback((event: Event) => {
        if (!ref.current || ref.current.contains(event.target as Node)) return;

        handler();
    }, [handler, ref]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);

        return () => {
            document.removeEventListener('mousedown', handleClick);
        }
    }, [ref, handler, handleClick]);
}

export const useLoading = () => {
    const promiseList: PromiseLike<unknown>[] = [];
    const [isLoading, setIsLoading] = useState(false);
    const [isCompletedOnce, setIsCompletedOnce] = useState(false);

    function checkIsLoading() {
        if (!!promiseList.length) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }

    function add<T>(callback: () => PromiseLike<T>) {
        const promise = callback();

        subscribe(promise);
        checkIsLoading();
        return promise;
    }

    function subscribe<T>(promise: PromiseLike<T>): PromiseLike<T> {
        if (promiseList.indexOf(promise) !== -1) {
            throw new Error('Promise is already registered!');
        }

        promiseList.push(promise);

        promise.then(() => complete(promise), () => complete(promise));
        return promise;
    }

    function complete(promise: PromiseLike<unknown>) {
        function timeout() {
            return new Promise((resolve) => {
                setTimeout(resolve, 0);
            });
        }

        timeout().then(() => {
            const index = promiseList.indexOf(promise);
            if (index === -1)
                throw new Error('Promise is not registered!');

            promiseList.splice(index, 1);
            setIsCompletedOnce(true);
            checkIsLoading();
        });
    }

    return { isLoading, isCompletedOnce, add, subscribe };
}

export const useDebounce = <T>(value: T, delay = 300) => {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);
  
    useEffect(() => {
        const timeout = setTimeout(() => setDebouncedValue(value), delay);
    
        return () => {
            clearTimeout(timeout);
        };
    }, [value, delay]);
  
    return debouncedValue;
};

export enum ResizeObserverType {
    All,
    Height,
    Width
}

export const useResizeObserver = <T>(ref: React.RefObject<T> | null, cb?: (entry: ResizeObserverEntry) => void, observerType?: ResizeObserverType) => {
    const contentWidth = useRef<number | null>(null);
    const contentHeight = useRef<number | null>(null);
    
    useEffect(() => {
        const targetEl = ref && 'current' in ref ? ref.current : null;
        if (!targetEl) return;

        const myObserver = new ResizeObserver(
            (entries: ResizeObserverEntry[]) => {
                for (let entry of entries) {
                    const { width, height } = entry.contentRect;

                    if (!observerType) {
                        cb?.(entry);
                    }

                    if (observerType === ResizeObserverType.Width && width !== contentWidth.current) {
                        cb?.(entry);
                    }

                    if (observerType === ResizeObserverType.Height && height !== contentHeight.current) {
                        cb?.(entry);
                    }

                    contentWidth.current = width;
                    contentHeight.current = height;
                }
            }
        );

        myObserver.observe(targetEl as unknown as HTMLElement);

        return () => {
            myObserver.unobserve(targetEl as unknown as HTMLElement);
        }
    }, [ref, cb, observerType]);
}
