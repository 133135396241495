import { useState } from 'react';


export type CheckboxTreeItem<T> = T & {
    uniqueId: string;
    value: string;
    isChecked: boolean;
    hasCheckedChildren?: boolean;
    children?: CheckboxTreeItem<T>[];
}

export function useCheckboxTreeStore<T>() {
    const [treeItems, setTreeItems] = useState<CheckboxTreeItem<T>[]>([]);

    const getItemById = (id: string): CheckboxTreeItem<T> | null => {
        let item: CheckboxTreeItem<T> | null = null;

        const findItem = (treeItems: CheckboxTreeItem<T>[]) => {
            for (const treeItem of treeItems) {
                if (treeItem.uniqueId === id) {
                    item = treeItem;
                    break;
                };

                if (treeItem.children) {
                    findItem(treeItem.children);
                }
            }
        }

        findItem(treeItems);

        return item;
    }

    const getCheckedItemUniqueIds = () => {
        const ids: string[] = [];

        const fillCheckedIds = (items: CheckboxTreeItem<T>[]) => {
            for (const treeItem of items) {
                if (treeItem.isChecked) ids.push(treeItem.uniqueId);
                if (!treeItem.children) continue;

                fillCheckedIds(treeItem.children);
            }
        }

        fillCheckedIds(treeItems);

        return ids;
    }

    const setIsTreeItemChecked = (itemId: string, isChecked: boolean) => {
        const getCheckedChildren = (items: CheckboxTreeItem<T>[]) => {
            return items.map(item => {
                item.isChecked = isChecked;
                item.hasCheckedChildren = isChecked;

                if (item.children) {
                    item.children = getCheckedChildren(item.children)
                }

                return item;
            })
        }

        const getTreeItemChild = (item: CheckboxTreeItem<T>) => {
            if (item.uniqueId === itemId) {
                item.isChecked = isChecked;
                item.hasCheckedChildren = isChecked;

                if (item.children) {
                    item.children = getCheckedChildren(item.children);
                }

                return item;
            }

            if (item.children) {
                let hasCheckedChildren = false;
                let checkedChildrenCount = 0;

                item.children = item.children.map(child => {
                    child = getTreeItemChild(child);

                    if (child?.isChecked) {
                        hasCheckedChildren = true;
                        checkedChildrenCount += 1;
                    }
                    
                    if (child.hasCheckedChildren) {
                        hasCheckedChildren = true;
                    }

                    return child;
                })

                item.hasCheckedChildren = hasCheckedChildren;
                item.isChecked = item.children.length === checkedChildrenCount;
            }

            return item;
        }

        const newTreeItems = treeItems.map(treeItem => getTreeItemChild(treeItem));
        setTreeItems(newTreeItems);
    }

    return {
        treeItems,
        setTreeItems,
        setIsTreeItemChecked,
        getCheckedItemUniqueIds,
        getItemById
    }
}
