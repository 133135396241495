import classNames from 'classnames';
import { IconType } from 'react-icons/lib';

import styles from './Widget.module.scss';


type WidgetProps = {
	title: string | JSX.Element;
    className?: string;
    children?: React.ReactNode;
    Icon?: IconType;
};

export const Widget: React.FC<WidgetProps> = ({ title, className, children, Icon }) => {

    return (
        <div className={classNames(styles.container, className)}>
            <div className={styles.header}>
                {!!Icon && <Icon className={styles.icon} />}
                <div className={styles.title}>{title}</div>
            </div>
            <div className={styles.body}>
                {children}
            </div>
        </div>
    );
};
