import { useCallback } from "react";

import { apiClient } from "@Models";

import { SinglePickerProps, SinglePicker } from "../Picker";

type ReportingPeriodQuaterPickerProps = SinglePickerProps & {
    loadDataParams?: {
        isOverview?: boolean,
    }
}

export const ReportingPeriodQuaterPicker: React.FC<ReportingPeriodQuaterPickerProps> = (props) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadData = useCallback(apiClient.reportingPeriodQuaterLookupGet.bind(apiClient), []);

    return (
        <SinglePicker
            {...props}
            loadData={loadData}
        />
    );
}
