import { useCallback } from "react";

import { apiClient } from "@Models";
import { OrgUnitType } from "@AppConstants";

import { SinglePickerProps, SinglePicker } from "../Picker";

type OrgUnitPickerProps = SinglePickerProps & {
    loadDataParams?: {
        objectType?: OrgUnitType,
        includeId?: number,
        validFrom?: Date,
        validTo?: Date
    }
}

export const OrgUnitPicker: React.FC<OrgUnitPickerProps> = (props) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadData = useCallback(apiClient.orgUnitLookupGet.bind(apiClient), []);

    return (
        <SinglePicker
            {...props}
            loadData={loadData}
        />
    );
}
