import { create } from 'zustand';

export type Breadcrumb = {
    index: number;
    title: string;
    href: string;
}

type BreadcrumbsStore = {
    breadcrumbs: Breadcrumb[];
    addBreadcrumb: (breadcrumb: Breadcrumb) => void;
    addBreadcrumbList: (breadcrumbList: Breadcrumb[]) => void;
    removeBreadcrumb: (breadcrumbIndex: number) => void;
    removeBreadcrumbList: (breadcrumbIndexes: number[]) => void;
}

export const useBreadcrumbsStore = create<BreadcrumbsStore>((set, get) => ({
    breadcrumbs: [],

    addBreadcrumb: (breadcrumb: Breadcrumb) => {
        const breadcrumbs = [...get().breadcrumbs, breadcrumb];

        set({ breadcrumbs });
    },

    addBreadcrumbList: (breadcrumbList: Breadcrumb[]) => {
        const breadcrumbs = [...get().breadcrumbs, ...breadcrumbList];

        set({ breadcrumbs });
    },

    removeBreadcrumb: (breadcrumbIndex: number) => {
        const breadcrumbs = [...get().breadcrumbs]
            .filter(item => item.index !== breadcrumbIndex);

        set({ breadcrumbs });
    },

    removeBreadcrumbList: (indexes: number[]) => {
        const breadcrumbs = [...get().breadcrumbs]
            .filter(item => !indexes.includes(item.index));

        set({ breadcrumbs });
    }
}));
