import { useRef, memo } from 'react';
import { CSSTransition } from 'react-transition-group';
import { IconType } from 'react-icons/lib';
import classNames from 'classnames';

import { useOnClickOutside } from '@Hooks';

import styles from './Dropdown.module.scss';


export type DropdownButton = {
    name: string;
    title?: string;
    className?: string;
    isDisabled?: boolean;
    Icon?: IconType;
    onClick?: () => Promise<void> | void;
}

type DropdownProps = {
    isOpen: boolean;
    buttons?: DropdownButton[];
    className?: string;
    children?: React.ReactNode;
    onOutsideClick?: () => void;
}

export const Dropdown: React.FC<DropdownProps> = memo(({ isOpen, buttons, className, children, onOutsideClick }) => {
    const dropdownRef = useRef<HTMLDivElement | null>(null);

    useOnClickOutside(dropdownRef, () => {
        onOutsideClick?.();
    });

    return (
        <CSSTransition
            in={isOpen}
            timeout={100}
            unmountOnExit
            classNames={{
                enter: styles.dropdownEnter,
                enterActive: styles.dropdownEnterActive,
                exit: styles.dropdownExit,
                exitActive: styles.dropdownExitActive,
            }}
        >
            <div ref={dropdownRef} className={classNames(styles.dropdown, className)}>
                {buttons?.map((button, index) => (
                    <div
                        key={index}
                        className={classNames(styles.button, button.isDisabled && styles.disabled, button.className)}
                        onClick={button.onClick}
                    >
                        {button.Icon && <button.Icon className={styles.icon} />}
                        <div className={styles.name} title={button.title}>{button.name}</div>
                    </div>
                ))}
                {children}
            </div>
        </CSSTransition>
    );
});
