import { PrincipalModel } from "@Models";
import styles from '../PersonPicker.module.scss';

export const renderCustomLabel = (option: PrincipalModel) => {
    const createOption = option as unknown as { value: string; label: string; __isNew__: boolean };

    if (createOption.__isNew__) {
        return <div>{createOption.label}</div>;
    }

    const displayName = option.displayName ? option.displayName + (option.email ? ` (${option.email})` : "") : option.email;
    return (
        <div key={option.id} className={styles.label} title={displayName}>
            <div style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{displayName}</div>
        </div>
    );
};
