import { Modal, Skeleton } from '@Components';
import styles from './ModalSkeleton.module.scss';

type ModalSkeletonProps = {
    width?: number;
    onClose?: () => void;
}

const ModalSkeleton: React.FC<ModalSkeletonProps> = ({ width, onClose }) => {
    const renderTitle = () => <Skeleton className={styles.headerItem} />;
    const renderFooter = () => (
        <div className={styles.footerItem}>
            <Skeleton className={styles.skeleton}/>
        </div> 
    );

    return (
        <Modal
            isOpen
            width={width}
            title={renderTitle()}
            footerRender={renderFooter}
            onClose={onClose}
        >
           <Skeleton className={styles.contentItem} />
        </Modal>
    );
}

export default ModalSkeleton;
