import { create } from 'zustand';

export type ModalData = {
    id: number;
    dialog: React.FunctionComponent<unknown>;
    props: unknown;
}

type ModalStore = {
    modalData: ModalData[];
    addModal: (modal: ModalData) => void;
    removeModal: (modalId: number) => void;
}

export const useModalStore = create<ModalStore>((set, get) => ({
    modalData: [],

    addModal: (modal) => {
        const modalData = get().modalData;
        if (modalData.find(item => item.id === modal.id)) return;

        set({ modalData: [...get().modalData, modal] });
    },

    removeModal: (modalId: number) => {
        const modalData = get().modalData;
        const filteredModalData = modalData.filter(item => item.id !== modalId);

        set({ modalData: filteredModalData });
    },
}))
