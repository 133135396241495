import { apiClient } from "@Models";


export const setPrincipalConfiguration = async (group: string, key: string, value?: string) => {
    await apiClient.principalConfigurationPut({ group, key, value });
}

export const getPrincipalConfiguration = async (group: string, key: string) => {
    const keys = `${group}.${key}`;

    const { data } = await apiClient.principalConfigurationGet({ keys });

    const config = data.find(config => config.group === group && config.key === key);
    return config?.value;
}
