import { useState } from 'react';

import TreeViewList from './Components/TreeViewList/TreeViewList';


export type TreeNode = {
    id: string;
    value: string;
    title?: string;
    hasChildren: boolean;
    children?: TreeNode[] | null;
};

type TreeViewProps = {
    nodes: TreeNode[];
    selectedNodeIds: string[];
    defaultExpandedNodeIds?: string[];
    expandedAllNodes?: boolean;
    canSelectParentNode?: boolean;
    className?: string;
    onNodeSelect?: (id: string) => void;
    onNodeDeselect?: (id: string) => void;
    onChildrenLoad?: (id: string) => Promise<void>;
};

export const TreeView: React.FC<TreeViewProps> = ({
    nodes, selectedNodeIds, defaultExpandedNodeIds, expandedAllNodes, className,
    canSelectParentNode, onNodeSelect, onNodeDeselect, onChildrenLoad
}) => {
    const [expandedNodeIds, setExpandedNodeIds] = useState<string[]>(defaultExpandedNodeIds || []);
    
    const handleExpand = (nodeId: string) => {
        setExpandedNodeIds([...expandedNodeIds, nodeId]);
    }

    const handleCollapse = (nodeId: string) => {
        if (!expandedNodeIds.includes(nodeId)) return;

        setExpandedNodeIds(expandedNodeIds.filter(i => i !== nodeId));
    }

    return (
        <div className={className}>
            <TreeViewList
                nodes={nodes}
                selectedNodeIds={selectedNodeIds}
                expandedNodeIds={expandedNodeIds}
                expandedAllNodes={expandedAllNodes}
                canSelectParentNode={canSelectParentNode}
                onNodeSelect={onNodeSelect}
                onNodeDeselect={onNodeDeselect}
                onNodeExpand={handleExpand}
                onNodeCollapse={handleCollapse}
                onChildrenLoad={onChildrenLoad}
            />
        </div>
    );
}
