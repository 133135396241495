import { memo, useCallback, useEffect, useState, useMemo } from 'react';
import Select, { GroupBase, MultiValue } from "react-select";

import { IPickerParams, PrincipalModel, apiClient } from '@Models';
import { useDebounce, useMountEffect } from '@Hooks';

import { FormFeedback, ReactSelectStyles } from '@Components';
import { usePaginatedLoading } from './Hooks/PersonPickerHooks';
import { renderCustomLabel } from './Helpers/PersonPickerHelpers';

type PersonPickerProps = {
    value?: number[] | null;
    placeholder?: string;
    disabled?: boolean;
    className?: string;
    pageSize?: number;
    invalid?: boolean;
    isCreatable?: boolean;
    isClearable?: boolean;
    validationErrors?: string[];
    onChange: (users: number[]) => void;
    onGetCustomRequestParams?: () => Partial<IPickerParams>;
}

export const MultiPersonPicker = memo((props: PersonPickerProps) => {
    const { value, isCreatable, className, disabled, isClearable, placeholder, invalid, validationErrors, onGetCustomRequestParams, onChange } = props;
    const { options, isLoading, loadFirstPage, loadNextPage, setOptions, setPage, setGraphNextLink } = usePaginatedLoading();

    const [inputValue, setInputValue] = useState('');
    const [isMounted, setIsMounted] = useState(false);

    const debouncedInputValue = useDebounce(inputValue);

    const getRequestParams = useCallback((inputValue: string = '') => {
        const params: Partial<IPickerParams> = onGetCustomRequestParams ? onGetCustomRequestParams() : {};
        if (inputValue) params.term = inputValue;
        if (value?.length) params.includePrincipalIds = value;
        if (isCreatable) params.useGraph = isCreatable;

        return params;
    }, [onGetCustomRequestParams, value, isCreatable]);

    useMountEffect(() => {
        loadFirstPage(getRequestParams());
        setIsMounted(true);
    });
    
    useEffect(() => {
        if (!isMounted) return;

        loadFirstPage(getRequestParams(debouncedInputValue));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onGetCustomRequestParams, debouncedInputValue]);

    const handleInputChange = (value: string) => {
        setInputValue(value);
    }

    const handleChange = async (users: MultiValue<PrincipalModel>) => {
        const value = [];
        for (let i = 0; i < users.length; i++) {
            const user = users[i];
            if (user.id) {
                value.push(user.id);
            }
            if (!user.id) {
                var newUserId = await handleCreateOption(user);
                if (newUserId) {
                    value.push(newUserId);
                }
            }
        }

        onChange(value);
    }

    const handleCreateOption = async (value: PrincipalModel) => {
        if (!value.objectId) {
            return;
        }
        
        const { data } = await apiClient.principalPost({ objectId: value.objectId });
        setOptions((options) => [...options.filter(x => x.objectId !== value.objectId), data]);
        setPage(0);
        setGraphNextLink(undefined);
        return data.id;
    }

    const selectedValue = useMemo(() => {
        return options.filter(option => option.id && value && value.includes(option.id));
    }, [options, value]);

    const visibleOptions = useMemo(() => {
        return options.filter(option => {
            const input = inputValue.toLowerCase();
            const displayName = option.displayName.toLowerCase();
            const email = option.email?.toLowerCase();

            return displayName.includes(input) || email?.includes(input);
        });
    }, [options, inputValue]);

    return (
        <>
            <Select<PrincipalModel, true>
                className={className}
                classNamePrefix="react-select"
                options={visibleOptions}
                value={selectedValue}
                inputValue={inputValue}
                isMulti
                isSearchable
                isLoading={isLoading}
                isDisabled={disabled}
                isClearable={isClearable}
                onChange={handleChange}
                onInputChange={handleInputChange}
                onMenuScrollToBottom={() => loadNextPage(getRequestParams(inputValue))}
                menuPortalTarget={document.body}
                menuPlacement="auto"
                formatOptionLabel={renderCustomLabel}
                styles={{ ...ReactSelectStyles<PrincipalModel, true, GroupBase<PrincipalModel>>(!!invalid), ...({}) }}
                placeholder={placeholder || ''}
                filterOption={() => true} // does not recognize new options with search open // options are filtered on BE
            />
            {!!validationErrors?.length && (
                <FormFeedback>
                    {validationErrors.join(' ')}
                </FormFeedback>
            )}
        </>
    );  
});
