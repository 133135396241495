import { useCallback } from "react";
import { apiClient } from "@Models";
import { SinglePickerProps, SinglePicker } from "../Picker";

type AgreementProductGroupPickerProps = SinglePickerProps & {
    loadDataParams: {
        agreementId: number,
        includeId?: number,
        validFrom?: Date,
        validTo?: Date
    }
}

export const AgreementProductGroupPicker: React.FC<AgreementProductGroupPickerProps> = (props) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadData = useCallback(apiClient.orgUnitAgreementPgsLookupGet.bind(apiClient), []);

    return (
        <SinglePicker
            {...props}
            loadData={loadData}
        />
    );
}
