import { TreeNode } from '@Components';

export const getFilteredTreeNodes = (treeNodes: TreeNode[], filter: string) => {
    const filterText = filter.toLowerCase();

    const filterByValue = (treeNode: TreeNode, filterText: string) => {
        const nodeValue = treeNode.value.toLowerCase();

        if (nodeValue.includes(filterText)) return true;

        if (treeNode.children?.length) {
            const filteredChildren = treeNode.children.filter(node => filterByValue(node, filterText));

            treeNode.children = filteredChildren;

            if (filteredChildren.length) return true;
        }

        return false;
    }

    return treeNodes.filter(node => filterByValue(node, filterText));
}
