import { useState } from 'react';
import classNames from 'classnames';
import { IconType } from 'react-icons/lib';
import { AiOutlineCaretDown, AiOutlineCaretUp } from 'react-icons/ai';

import styles from './Accordion.module.scss';
import { Loading } from '../Loading/Loading';

export type AccordionButton = {
    title?: string;
    className?: string;
    isDisabled?: boolean;
    Icon: IconType;
    onClick?: () => Promise<void> | void;
}

type AccordionProps = {
    title: string | JSX.Element;
    className?: string;
    contentClassName?: string;
    isHiddenByDefault?: boolean;
    isLoading?: boolean;
    buttons?: AccordionButton[];
    children: React.ReactNode;
};

export const Accordion: React.FC<AccordionProps> = ({ title, isHiddenByDefault, className, contentClassName, isLoading, buttons, children }) => {
    const [isOpen, setIsOpen] = useState(!isHiddenByDefault);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    }

    const handleButtonClick = (event: MouseEvent, onClick?: () => Promise<void> | void) => {
        event.stopPropagation();

        void onClick?.();
    }

    return (
        <>
            <div className={classNames(styles.accordion, className)} onClick={handleToggle}>
                {isOpen ? (
                    <AiOutlineCaretUp className={styles.icon} />
                ) : (
                    <AiOutlineCaretDown className={styles.icon} />
                )}

                <div className={styles.title}>
                    {title}
                    {buttons && (
                        <div className={styles.buttons}>
                            {buttons.map((button, index) => (
                                <div
                                    key={index}
                                    className={classNames(styles.button, button.isDisabled && styles.disabled, button.className)}
                                    onClick={event => handleButtonClick(event as unknown as MouseEvent, button.onClick)}
                                >
                                    {<button.Icon />}
                                </div>
                            ))}
                        </div>
                    )}
                    {isLoading && <Loading className={styles.loading} />}
                </div>
            </div>
            <div className={classNames(styles.content, !isOpen && styles.hidden, contentClassName)}>
                {children}
            </div>
        </>
    );
};
