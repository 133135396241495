import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import styles from './VerticalSplitter.module.scss';

type VerticalSplitterProps = {
    width?: number;
    minWidth?: number;
    maxWidth?: number;
    className?: string;
    disabled?: boolean;
    onChangeWidth: (newWidth: number) => void;
};

const VerticalSplitter: React.FC<VerticalSplitterProps> = ({ width, minWidth, maxWidth, className, disabled, onChangeWidth }) => {
    const [splitterXPosition, setSplitterXPosition] = useState<number | null>(null);
    const [isActive, setIsActive] = useState(false);

    const splitterRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    });

    const handleMouseDown = (event: React.MouseEvent<Element>) => {
        const splitterNode = splitterRef.current;
        if (!splitterNode) return;

        setSplitterXPosition(event.clientX);
        setIsActive(true);
    }

    const handleMouseUp = () => {
        setIsActive(false);
    }

    const handleMouseMove = (e: Event) => {
        if (!isActive || !width || !splitterXPosition) return;

        e.preventDefault();

        const event = e as unknown as React.MouseEvent<Element>;

        const newWidth = width + event.clientX - splitterXPosition;
        setSplitterXPosition(event.clientX);
    
        if (minWidth && newWidth < minWidth) {
            onChangeWidth(minWidth);
            return;
        }

        if (maxWidth && newWidth > maxWidth) {
            onChangeWidth(maxWidth);
            return;
        }
    
        onChangeWidth(newWidth);
    };

    return (
        <div
            ref={splitterRef}
            className={classNames(styles.wrapper, isActive && styles.active, disabled && styles.disabled, className)}
            onMouseDown={handleMouseDown}
        >
            <div className={styles.splitter}></div>
        </div>
    );
}

export default VerticalSplitter;
