import { memo } from 'react';
import classNames from 'classnames';

import { useBreadcrumbsStore } from './Store/BreadcrumbsStore';
import BreadcrumbsItem from './Components/BreadcrumbsItem/BreadcrumbsItem';
import styles from './Breadcrumbs.module.scss';

type BreadcrumbsProps = {
    className?: string;
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = memo(({ className }) => {
    const breadcrumbs = useBreadcrumbsStore(state => state.breadcrumbs);

    if (!breadcrumbs.length || breadcrumbs.length === 1) return null;

    return (
        <div className={classNames(styles.container, className)}>

            {breadcrumbs.sort((a, b) => a.index - b.index).map((item, itemIndex, itemList) => {
                return (
                    <BreadcrumbsItem
                        key={item.index}
                        title={item.title}
                        href={item.href}
                        isLast={itemIndex === itemList.length - 1}
                    />
                );
            })}

        </div>
    );
});
