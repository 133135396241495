import React from 'react';
import { RxCross2 } from 'react-icons/rx';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';

import { IconButton } from '@Components';
import styles from './RightSidebar.module.scss';

type RightSidebarProps = {
    title: string;
    isOpen: boolean;
    width?: number;
    contentClassName?: string;
    children: React.ReactNode;
    buttonsRender?: () => React.ReactNode;
    onClose: () => void;
}

export enum EditSidebarType {
    Create,
    Update,
    None
}

export const RightSidebar: React.FC<RightSidebarProps> = React.memo(({ title, isOpen, width, contentClassName, children, buttonsRender, onClose }) => {

    const handleCloseButtonClick = () => {
        onClose();
    }

    return (
        <CSSTransition
            in={isOpen}
            timeout={300}
            unmountOnExit
            classNames={{
                enter: styles.sidebarEnter,
                enterActive: styles.sidebarEnterActive,
                exit: styles.sidebarExit,
                exitActive: styles.sidebarExitActive,
            }}
        >
            <div className={styles.sidebar} style={{ width }}>
                <div className={styles.header}>{title}</div>
                <IconButton
                    title="Close"
                    className={styles.icon}
                    IconType={RxCross2}
                    onClick={handleCloseButtonClick}
                />

                <div className={classNames(styles.content, contentClassName, buttonsRender && styles.hasButtons)}>
                    {children}
                </div>

                {buttonsRender && (
                    <div className={styles.buttons}>
                        {buttonsRender()}
                    </div>
                )}
            </div>
        </CSSTransition>
    );
});
