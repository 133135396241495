import React, { useRef, useState } from 'react';
import classNames from 'classnames';

import { KeyboardCodes } from '@AppConstants';

import { NumberTextarea, NumberTextareaProps } from '@Components';
import styles from './InlineEditableCell.module.scss';
import { toUINumber } from '@Helpers';


type InlineEditableNumberCellProps = Omit<NumberTextareaProps, 'onChange'> & {
    initialValue: string;
	isBlurFormatterEnabled?: boolean;
    onChange?: (value: string) => void;
}

export const InlineEditableNumberCell: React.FC<InlineEditableNumberCellProps> = ({
    initialValue, isBlurFormatterEnabled, maxDecimalLength, onChange, ...props
}) => {
    const [value, setValue] = useState(initialValue);
    const [isFocus, setIsFocus] = useState(false);

    const inputRef = useRef<HTMLTextAreaElement | null>(null);

    const handleChange = (value: string) => {
        setValue(value);
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        const { key } = event;

        if (key === KeyboardCodes.ArrowLeft || key === KeyboardCodes.ArrowRight
            || key === KeyboardCodes.ArrowUp || key === KeyboardCodes.ArrowDown) {
            event.stopPropagation();
        }

        if (key === KeyboardCodes.Enter) {
            inputRef.current?.blur();
        }

        if (key === KeyboardCodes.Escape) {
            setValue(initialValue);
            setTimeout(() => inputRef.current?.blur(), 0);
        }
    }

    const handleFocus = (event: React.FocusEvent<HTMLTextAreaElement>) => {
        props.onFocus?.(event);

        setIsFocus(true);
    }

    const handleBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
        props.onBlur?.(event);

        setIsFocus(false);

        if (initialValue === value) return;
        onChange?.(value);
    }

    return (
        <div className={styles.wrapper}>
            <NumberTextarea
                {...props}
                innerRef={inputRef}
                value={isBlurFormatterEnabled && !isFocus ? toUINumber(+value, maxDecimalLength) : value}
                className={classNames(styles.input, props.className)}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                onBlur={handleBlur}
                onFocus={handleFocus}
            />
        </div>
    );
}
