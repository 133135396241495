import {
    AiFillDelete,
    AiFillEdit,
    AiOutlineApartment,
    AiOutlinePlusCircle,
    AiOutlineEye,
    AiOutlineFile,
    AiOutlineLink,
    AiOutlineInfoCircle,
    AiOutlineEnter,
    AiOutlineDownload,
    AiOutlineFilePdf,
    AiTwotoneFile,
    AiOutlineMinusCircle
} from 'react-icons/ai';
import {
    FiRotateCw
} from 'react-icons/fi';
import { IconType } from 'react-icons';
import { MdContentCopy } from 'react-icons/md';
import { ImLab, ImCalendar, ImCog } from 'react-icons/im';
import classNames from 'classnames';

import styles from './ActionCell.module.scss';

type ActionButtonName = 'edit'
    | 'delete'
    | 'copy'
    | 'add'
    | 'remove'
    | 'parent'
    | 'eye'
    | 'referenced'
    | 'info'
    | 'inside'
    | 'document'
    | 'uploadedDocument'
    | 'download'
    | 'downloadPdf'
    | 'lab'
    | 'calendar'
    | 'cog'
    | 'rotate';

export enum ActionCellType {
    icon = 'icon',
    button = 'button'
}

export type ActionButton = {
    name?: ActionButtonName;
    size?: number | string;
    visible?: boolean;
    disabled?: boolean;
    title?: string;
    hint?: string;
    className?: string;
    onClick?: () => void;
};

type ActonsCellProps = {
    buttons: ActionButton[];
};

const DEFAULT_SIZE = 22;

export const ActionCell: React.FC<ActonsCellProps> = ({ buttons }) => {

    const renderButton = (button: ActionButton) => {
        const { name, title, size, onClick, disabled, className } = button;
        let Icon: IconType | null = null;

        if (name === 'edit') Icon = AiFillEdit;
        if (name === 'delete') Icon = AiFillDelete;
        if (name === 'copy') Icon = MdContentCopy;
        if (name === 'add') Icon = AiOutlinePlusCircle;
        if (name === 'remove') Icon = AiOutlineMinusCircle;
        if (name === 'parent') Icon = AiOutlineApartment;
        if (name === 'eye') Icon = AiOutlineEye;
        if (name === 'referenced') Icon = AiOutlineLink;
        if (name === 'info') Icon = AiOutlineInfoCircle;
        if (name === 'inside') Icon = AiOutlineEnter;
        if (name === 'document') Icon = AiOutlineFile;
        if (name === 'uploadedDocument') Icon = AiTwotoneFile;
        if (name === 'download') Icon = AiOutlineDownload;
        if (name === 'downloadPdf') Icon = AiOutlineFilePdf;
        if (name === 'lab') Icon = ImLab;
        if (name === 'calendar') Icon = ImCalendar;
        if (name === 'cog') Icon = ImCog;
        if (name === 'rotate') Icon = FiRotateCw;

        if (!Icon) return null;

        return <Icon
            size={size || DEFAULT_SIZE}
            title={title}
            className={classNames(styles.button, disabled && styles.disabled, className)}
            onClick={!disabled ? onClick : undefined}
        />;
    }

    return (
        <div className={styles.container}>
            {buttons.map((button, i) => {
                const { visible } = button;

                return (
                    <span key={i} style={{ gridColumn: `${i + 1} / ${i + 2}` }}>
                        {(visible === undefined || visible === true) ? renderButton(button) : null}
                    </span>
                )
            })}
        </div>
    );
}
