/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-mixed-operators */
import axios, { AxiosError, AxiosInstance, AxiosPromise, AxiosRequestConfig, AxiosResponse, CancelToken, CancelTokenSource, ResponseType } from 'axios';
import { devInfoStore, getCurrentToken, getImpersonatedPrincipal, login } from '@Stores';
import { DateTimeService } from './DateTimeService';
import { errorHandleService } from './ErrorHandleService';
//import { errorHandleService } from './ErrorHandleService';
//import { PromiseCompletion } from '@Helpers';

export type ApiHeaders = {
    [key: string]: string;
}

export interface IAjaxOptions {
    responseType?: ResponseType;
    hideModalLoader?: boolean;
    requestKey?: string;
    transformDateTime?: boolean;
    operationName?: string;
    noDateTransform?: boolean;
    suppressErrorHandling?: boolean;
    cancelDuplicatedRequests?: boolean;
    cancellationToken?: CancelToken;
    customApiHeader?: ApiHeaders;
}

type AxiosMethodDefinition = (url: string, data?: any, config?: AxiosRequestConfig) => AxiosPromise;
type AxiosMethodChooser = (instance: AxiosInstance) => AxiosMethodDefinition;

export type Response = AxiosResponse;
export type ResponseError<T> = AxiosError<T>;
export type ResponseInterceptor<T> = {
    response: (value: Response) => Response | Promise<Response>,
    error: (err: ResponseError<T>) => ResponseError<T>
};

export enum AxiosMethod {
    GET = 'GET',
    DELETE = 'DELETE',
    HEAD = 'HEAD',
    OPTIONS = 'OPTIONS',
    POST = 'POST',
    PUT = 'PUT',
    PATCH = 'PATCH',
}

export enum ApiHeadersKeys {
    CacheControl = 'Cache-Control',
    Authorization = 'Authorization',
    AppImpersonationPrincipalId = 'App-Impersonation-PrincipalId'
}

export class ApiService {
    private static _responseInterceptors: ResponseInterceptor<unknown>[] = [];
    private static _cancelTokenCollection: Map<string, CancelTokenSource> = new Map();

    public static addResponseInterceptor(interceptor: ResponseInterceptor<unknown>) {
        ApiService._responseInterceptors.push(interceptor);
    }

    private static _addAndChechCancelationToken(tokenKey: string, source: CancelTokenSource) {
        const duplicateToken = ApiService._cancelTokenCollection.get(tokenKey);
        if (duplicateToken) {
            duplicateToken.cancel();
        }
        ApiService._cancelTokenCollection.set(tokenKey, source);
    }

    private static _callMethod<TResponse>(methodChooser: AxiosMethodChooser, url: string, data?: any, options?: IAjaxOptions): AxiosPromise<TResponse> {
        const responseType = options && options.responseType || 'json';

        const requestKey = options?.requestKey ? options.requestKey : this._getAbsoluteUrl(url);

        let cancelToken = options?.cancellationToken;
        if (options?.cancelDuplicatedRequests) {
            const source = axios.CancelToken.source();
            cancelToken = source.token;
            ApiService._addAndChechCancelationToken(requestKey, source);
        }
        const instance = ApiService._getInstance(responseType, options, cancelToken);

        const result = methodChooser(instance)(this._getAbsoluteUrl(url), data);
        const isHandleError = !(options && options.suppressErrorHandling);

        result.then((data) => {
            try {
                let response = typeof data === 'string' ? JSON.parse(data) : data;
                return ApiService.typeCheck(response && typeof response === 'string' ? JSON.parse(response) : response);
            } catch (error) {
                throw Error(`[requestClient] Error parsing response JSON data - ${JSON.stringify(error)}`);
            }
        })
        result.catch((error: any) => {
            if (isHandleError) this.handleError(url, error);
        });

        return result;
    }

    public static getRequestUrl(baseUrl: string, operation: any) {
        console.log(baseUrl, operation);
    }

    public static sendRequest<TResponse>(method: AxiosMethod, url: string, data?: any, options?: IAjaxOptions): AxiosPromise<TResponse> {
        switch (method) {
            case AxiosMethod.POST:
                return ApiService.postTypedData<TResponse>(url, data, options);
            case AxiosMethod.GET:
                return ApiService.getTypedData<TResponse>(url, data, options);
            case AxiosMethod.PUT:
                return ApiService.putTypedData<TResponse>(url, data, options);
            case AxiosMethod.DELETE:
                return ApiService.deleteData<TResponse>(url, data, options);
            case AxiosMethod.PATCH:
                return ApiService.patchTypedData<TResponse>(url, data, options);
            default:
                return Promise.reject();
        }
    }

    public static getData<TResponse = unknown>(url: string, getData?: any, options?: IAjaxOptions): AxiosPromise<TResponse> {
        return this.getTypedData<TResponse>(url, getData, options);
    }

    public static getTypedData<TResponse>(url: string, getData?: any, options?: IAjaxOptions): AxiosPromise<TResponse> {
        return ApiService._callMethod<TResponse>((instance: AxiosInstance) => instance.get, url, { params: getData, paramsSerializer: ApiService._paramsSerializer }, options);
    }

    public static putData<TResponse = unknown>(url: string, putData?: any, options?: IAjaxOptions): AxiosPromise<TResponse> {
        return ApiService._callMethod<TResponse>((instance: AxiosInstance) => instance.put, url, putData, options);
    }

    public static putTypedData<TResponse>(url: string, putData?: any, options?: IAjaxOptions): AxiosPromise<TResponse> {
        return ApiService._callMethod<TResponse>((instance: AxiosInstance) => instance.put, url, putData, options);
    }

    public static postData<TResponse = unknown>(url: string, postData?: any, options?: IAjaxOptions): AxiosPromise<TResponse> {
        return ApiService._callMethod<TResponse>((instance: AxiosInstance) => instance.post, url, postData, options);
    }

    public static postTypedData<TResponse>(url: string, postData?: any, options?: IAjaxOptions): AxiosPromise<TResponse> {
        return ApiService._callMethod<TResponse>((instance: AxiosInstance) => instance.post, url, postData, options);
    }

    public static patchTypedData<TResponse>(url: string, patchData?: any, options?: IAjaxOptions): AxiosPromise<TResponse> {
        return ApiService._callMethod<TResponse>((instance: AxiosInstance) => instance.patch, url, patchData, options);
    }

    public static deleteData<TResponse>(url: string, deleteData?: any, options?: IAjaxOptions): AxiosPromise<TResponse> {
        return ApiService._callMethod<TResponse>((instance: AxiosInstance) => instance.delete, url, { params: deleteData }, options);
    }

    public static handleError(url: string, error: any) {
        if (axios.isCancel(error)) {
            console.log(`Request canceled: ${url}\nMessage: ${error.message}`);
            return;
        }

        if (error && error.response && error.response.status === 409) {
            return;
        }

        if (devInfoStore.isDevelopment && error && error.response && error.response.status === 503) {
            window.location.href = '/update';
        }

        void errorHandleService.showError(url, error);
    }

    public static toQueryString(params: { [key: string]: string }) {
        if (typeof (params) !== 'object') return '';
        return `?${Object.keys(params).map(k => `${k}=${params[k]}`).join('&')}`;
    }

    static typeCheck = (el: any) => {
        if (!el) return el;
        let typeEl = el;
        switch (typeof el) {
            case 'string':
                typeEl = ApiService.strCheck(el);
                break;
            case 'object':
                typeEl = Array.isArray(el) ? ApiService.arrCheck(el) : ApiService.objCheck(el);
                break;
        }
        return typeEl;
    };

    private static _encodeParamValue(value: string | number | boolean) {
        return encodeURIComponent(value)
            .replace(/%40/gi, '@')
            .replace(/%3A/gi, ':')
            .replace(/%24/g, '$')
            .replace(/%2C/gi, ',')
            .replace(/%20/g, '+')
            .replace(/%5B/gi, '[')
            .replace(/%5D/gi, ']');
    }

    private static _parseParamValue(value: string | number | boolean | object) {
        if (value instanceof Date)
            return value.toISOString();

        if (Array.isArray(value))
            return value.toString();

        if (typeof value === 'object')
            return JSON.stringify(value);

        return value;
    }

    private static _paramsSerializer(params: { [name: string]: unknown }[]) {
        const parts: string[] = [];

        for (const key in params) {
            const value = params[key];

            if (value === null || typeof value === 'undefined') continue;

            if (Array.isArray(value)) {
                for (const arrayItem of value) {
                    const parsedItemValue = ApiService._parseParamValue(arrayItem);
                    const part = key + '=' + ApiService._encodeParamValue(parsedItemValue);

                    parts.push(part);
                }

                continue;
            }

            const parsedValue = ApiService._parseParamValue(value);
            const part = key + '=' + ApiService._encodeParamValue(parsedValue);

            parts.push(part);
        }

        return parts.join('&');
    }

    private static strCheck = (str: string) => {
        const isDate = DateTimeService.ISO_8601_date.test(str);

        if (isDate) return DateTimeService.fromString(str);
        return str;
    };

    private static arrCheck = (array: any) => {
        return array.map((el: any) => {
            return ApiService.typeCheck(el);
        });
    };

    private static objCheck = (obj: any) => {
        Object.keys(obj).forEach(key => {
            obj[key] = ApiService.typeCheck(obj[key]);
        });
        return obj;
    };

    private static _getInstance(responseType: ResponseType = 'json', options?: IAjaxOptions, cancelToken?: CancelToken): AxiosInstance {
        const headers = ApiService._createRequestHeaders(options);
        const axiosConfig: AxiosRequestConfig = {
            responseType,
            headers,
            cancelToken
        };

        const axiosInstance = axios.create(axiosConfig);

        this._useInterceptors(axiosInstance, responseType);
        ApiService._responseInterceptors.forEach(x => axiosInstance.interceptors.response.use(x.response, x.error));

        return axiosInstance;
    }

    private static _useInterceptors(axiosInstance: AxiosInstance, responseType: string) {
        axiosInstance.interceptors.response.use((res) => {
            const contentType = res.headers['content-type'];
            if (contentType && contentType.indexOf('text/html') > -1 && responseType === 'json') {
                throw new Error('API call to ' + res.config.url + ' returned not expected content type: ' + contentType);
            }

            return res;
        }, (err) => {
            if (err.response && err.response.status === 401) {
                return new Promise(() => {
                    login();
                });
            }
            throw err;
        });
    }

    private static _createRequestHeaders(options?: IAjaxOptions): ApiHeaders {
        const headers = options && options.customApiHeader || {} as ApiHeaders;
        headers[ApiHeadersKeys.CacheControl] = headers[ApiHeadersKeys.CacheControl] || 'no-cache';

        const currentToken = getCurrentToken();
        const impersonatedPrincipal = getImpersonatedPrincipal();
        if (currentToken) {
            headers[ApiHeadersKeys.Authorization] = headers[ApiHeadersKeys.Authorization] || 'Bearer ' + currentToken;
        }
        if (impersonatedPrincipal && impersonatedPrincipal.principalId) {
            headers[ApiHeadersKeys.AppImpersonationPrincipalId] = impersonatedPrincipal.principalId.toString();
        }

        return headers;
    }

    private static _getAbsoluteUrl(url: string): string {
        let relariveUrl = url;
        if (relariveUrl && relariveUrl[0] !== '/') relariveUrl = '/' + url;
        if (!relariveUrl.startsWith('/api')) relariveUrl = '/api' + url;
        return window.location.origin + relariveUrl;
    }

    public static getDynamicUrl(url: string, params?: any & {}): string {
        let _url = url;
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                const valueRaw = params[key];
                const value = valueRaw instanceof Date ? DateTimeService.toQueryParam(valueRaw) : valueRaw;
                _url = _url.replace(`{${key}}`, encodeURIComponent(value as string));
            }
        }
        return _url;
    }
}
