import { memo, useRef, useState } from 'react';
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from 'react-icons/ai';
import classNames from 'classnames';

import { IconButton } from '@Components';
import VerticalSplitter from './Components/VerticalSplitter/VerticalSplitter';
import styles from './ResizablePanel.module.scss';

const DEFAULT_WIDTH = 250;

type ResizablePanelProps = {
    defaultWidth?: number;
    minWidth?: number;
    maxWidth?: number;
    className?: string;
    hiddenClassName?: string;
    hideButtonClassName?: string;
    children?: React.ReactNode;
};

export const ResizablePanel: React.FC<ResizablePanelProps> = memo(({ defaultWidth, minWidth, maxWidth, className, hiddenClassName, hideButtonClassName, children }) => {
    const [width, setWidth] = useState<number>(defaultWidth || DEFAULT_WIDTH);
    const [isHidden, setIsHidden] = useState(false);

    const panelRef = useRef<HTMLDivElement | null>(null);

    const handleChangeWidth = (newWidth: number) => {
        setWidth(newWidth);
    }

    const handleHideContentClick = () => {
        setIsHidden(true);
        setWidth(0);
    }

    const handleShowContentClick = () => {
        setIsHidden(false);
        setWidth(defaultWidth || DEFAULT_WIDTH);
    }

    return (
        <div
            ref={panelRef}
            className={classNames(styles.container, isHidden && styles.hidden, isHidden && hiddenClassName, className)}
            style={{ width: width ? width + 'px' : undefined }}
        >
            <div className={styles.content}>
                {children}
            </div>
            
            <VerticalSplitter
                width={width}
                minWidth={minWidth}
                maxWidth={maxWidth}
                className={styles.splitter}
                disabled={isHidden}
                onChangeWidth={handleChangeWidth}
            />

            {isHidden ? (
                <IconButton
                    title="Show panel"
                    className={classNames(styles.hideButton, hideButtonClassName)}
                    IconType={AiOutlineDoubleRight}
                    onClick={handleShowContentClick}
                />
            ) : (
                <IconButton
                    title="Hide panel"
                    className={classNames(styles.hideButton, hideButtonClassName)}
                    IconType={AiOutlineDoubleLeft}
                    onClick={handleHideContentClick}
                />
            )}
        </div>
    );
});
