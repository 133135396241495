import { useCallback, useEffect, useState } from "react";
import { AxiosPromise, AxiosResponse } from "axios";

import { apiClient } from "@Models";


export function useDxDatagridStore<T>(loadOptionsApi?: () => PromiseLike<AxiosResponse<T[]>> | AxiosPromise<T[] | T>) {
    const [gridOptions, setGridOptions] = useState<T[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const loadGridOptions = useCallback(async () => {
        if (!loadOptionsApi) {
            setGridOptions([]);
        } else {
            const fn = loadOptionsApi.bind(apiClient);
            try {
                setIsLoading(true);

                const { data } = await fn();
                if (Array.isArray(data)) {
                    setGridOptions(data);
                }
                else {
                    setGridOptions([data]);
                }
            } finally {
                setIsLoading(false);
            }
        }
    }, [loadOptionsApi]);

    useEffect(() => {
        loadGridOptions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        gridOptions,
        isGridLoading: isLoading,
        loadGridOptions
    };
}

export function useDxDatagridStoreWithTracking<T>(loadOptionsApi?: () => PromiseLike<AxiosResponse<T[]>> | AxiosPromise<T[] | T>) {
    const [gridOptions, setGridOptions] = useState<T[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const loadGridOptions = useCallback(async () => {
        if (!loadOptionsApi) {
            setGridOptions([]);
        } else {
            const fn = loadOptionsApi.bind(apiClient);
            try {
                setIsLoading(true);

                const { data } = await fn();
                if (Array.isArray(data)) {
                    setGridOptions(data);
                }
            } finally {
                setIsLoading(false);
            }
        }
    }, [loadOptionsApi]);

    useEffect(() => {
        loadGridOptions();
    }, [loadGridOptions]);

    return {
        gridOptions,
        isGridLoading: isLoading,
        loadGridOptions, 
    };
}
