import React, { memo } from 'react';
import classNames from 'classnames';

import { FormFeedback } from '../FormFeedback/FormFeedback';
import styles from './TextArea.module.scss';

export type TextAreaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
	invalid?: boolean;
	innerRef?: React.Ref<HTMLTextAreaElement>;
	validationErrors?: string[];
};

export const TextArea = memo((props: TextAreaProps) => {
	const { innerRef, className, invalid, validationErrors, value, ...rest } = props;

	const isInvalid = invalid || validationErrors?.length;

	const renderFeedback = () => {
		if (!validationErrors?.length) return null;

		return (
			<FormFeedback>
				{validationErrors.join(' ')}
			</FormFeedback>
		)
	}

	return (
		<>
			<textarea
				{...rest}
				ref={innerRef}
				className={classNames(styles.textarea, isInvalid && styles.invalid, className)}
				title={value?.toString()}
                value={value}
			/>
			{renderFeedback()}
		</>
	);
});
