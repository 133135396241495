import { useCallback } from "react";
import { apiClient } from "@Models";
import { SinglePicker, SinglePickerProps } from "../Picker";

export const RevenueModifierPicker: React.FC<SinglePickerProps> = (props) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadData = useCallback(apiClient.revenueModifierLookupGet.bind(apiClient), []);

    return (
        <SinglePicker
            {...props}
            loadData={loadData}
        />
    );
}
