import React from 'react';
import classNames from 'classnames';

import styles from './Radio.module.scss';

type RadioProps = React.InputHTMLAttributes<HTMLInputElement> & {
	invalid?: boolean;
	innerRef?: React.Ref<HTMLInputElement>;
};

export const Radio: React.FC<RadioProps> = ({ innerRef, className, invalid, ...props }) => {

	return (
        <label className={classNames(styles.wrapper, invalid && styles.invalid, className)}>
            <input {...props} type="checkbox" ref={innerRef} />
            <div className={styles.checkmark} />
        </label>
	);
}
