import { useCallback } from "react";

import { apiClient } from "@Models";

import { SingleStringPickerProps, SingleStringPicker } from "../Picker";

export const AttachmentTypePicker: React.FC<SingleStringPickerProps> = (props) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadData = useCallback(apiClient.enumAttachmentTypeGet.bind(apiClient), []);

    return (
        <SingleStringPicker
            {...props}
            loadData={loadData}
        />
    );
}
