
import { memo, useState } from 'react';
import classNames from 'classnames';
import { IconType } from 'react-icons/lib';
import { AiOutlineCaretDown } from 'react-icons/ai';

import { Dropdown } from '../Dropdown/Dropdown';
import styles from './Button.module.scss';


export enum ButtonType {
    Default,
    Success,
    Danger
}

type DropdownButton = Omit<ButtonProps, 'dropdownButtons'>;

type ButtonProps = {
    name?: string;
    title?: string;
    className?: string;
    wrapperClassName?: string;
    disabled?: boolean;
    type?: ButtonType;
    dropdownButtons?: DropdownButton[];
    Icon?: IconType;
    onClick?: () => Promise<void> | void;
};

export const Button: React.FC<ButtonProps> = memo(({
    name, title, className, wrapperClassName, disabled, type, dropdownButtons, Icon, onClick
}) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleClick = () => {
        if (disabled) return;

        void onClick?.();
    }

    const handleDropdownButtonClick = () => {
        setIsDropdownOpen(true)
    }

    const getDropdownButtons = () => {
        return dropdownButtons?.map(button => {
            const { name, title, className, disabled, Icon, onClick } = button;

            return {
                name: name || '',
                title,
                className,
                isDisabled: disabled,
                Icon,
                onClick
            }
        })
    }

    return (
        <div className={classNames(styles.wrapper, wrapperClassName)}>
            <div
                title={title}
                className={classNames(
                    styles.button,
                    type === ButtonType.Success && styles.success,
                    type === ButtonType.Danger && styles.danger,
                    dropdownButtons && styles.hasChildren,
                    disabled && styles.disabled,
                    className
                )}
                onClick={handleClick}
            >
                {Icon && <Icon />}
                {name && <div className={styles.name}>{name}</div>} 
            </div>

            {!!dropdownButtons && (
                <div className={styles.dropdownButton} onClick={handleDropdownButtonClick}>
                    <AiOutlineCaretDown />

                    <Dropdown
                        isOpen={isDropdownOpen}
                        onOutsideClick={() => setIsDropdownOpen(false)}
                        buttons={getDropdownButtons()}
                    />
                </div>
            )}
        </div>
    );
});
