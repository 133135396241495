import React, { useState } from 'react';
import { FaEye, FaEyeSlash, FaUserSecret } from 'react-icons/fa';

import { devInfoStore, getImpersonatedPrincipal, useAuthStore } from '@Stores';

import { IconButton } from '@Components';
import ImpersonationRightSidebar from '../ImpersonationRightSidebar/ImpersonationRightSidebar';
import styles from './ImpersonationButtons.module.scss';

export type ImpersonationProps = {
    className?: string;
};

export const ImpersonationButtons: React.FC<ImpersonationProps> = React.memo(({ className }) => {
    const appSecurityModel = useAuthStore(state => state.appSecurityModel);

    const [isImpersonationSidebarOpen, setIsImpersonationSidebarOpen] = useState(false);

    const handleImpersonationSidebarClose = () => {
        setIsImpersonationSidebarOpen(false);
    }

    const handleImpersonateClick = () => {
        setIsImpersonationSidebarOpen(true);
    };

    const handleUnImpersonateClick = () => {
        localStorage.removeItem('impersonatedPrincipal');
        window.location.href = '/';
    }

    if ((!devInfoStore.isDevelopment && !devInfoStore.isStaging && !devInfoStore.isIntegration) || !appSecurityModel || (!getImpersonatedPrincipal() && !appSecurityModel.hasPrincipalApplicationAdministrationRead))
        return null;

    return (
        <>
            <ImpersonationRightSidebar
                isOpen={isImpersonationSidebarOpen}
                onClose={handleImpersonationSidebarClose}
            />

            <div className={styles.container}>
                <IconButton
                    title="Impersonate"
                    className={className}
                    IconType={getImpersonatedPrincipal() ? FaUserSecret : FaEye}
                    onClick={handleImpersonateClick}
                />
                {getImpersonatedPrincipal() &&
                    <IconButton
                        title="Exit impersonation"
                        className={className}
                        IconType={FaEyeSlash}
                        onClick={handleUnImpersonateClick}
                    />
                }
            </div>
        </>
    );
});
