import { RxCross2 } from 'react-icons/rx';
import classNames from 'classnames';

import { Loading, Button, ButtonType } from '@Components';
import styles from './Modal.module.scss';


const DEFAULT_WIDTH = 500;

export type ModalButton = {
    name: string;
    title?: string;
    type?: ButtonType;
    isDisabled?: boolean;
    alignLeft?: boolean;
    children?: Omit<ModalButton, 'children'>[];
    onClick?: () => Promise<void> | void;
}

export type ModalProps = {
    isOpen: boolean;
    title: string | JSX.Element;
    className?: string;
    width?: number;
    buttons?: ModalButton[];
    isLoading?: boolean;
    children: React.ReactNode;
    onClose?: () => void;
    footerRender?: () => JSX.Element;
}

export const Modal: React.FC<ModalProps> = ({
    isOpen, title, className, width, buttons, isLoading, children,
    onClose, footerRender
}) => {
    const handleClose = () => {
        onClose?.();
    }

    const renderButtons = (buttons: ModalButton[]) => (
        buttons.map((button, index) => (
            <Button
                key={index}
                name={button.name}
                title={button.title}
                type={button.type}
                className={styles.button}
                disabled={button.isDisabled}
                dropdownButtons={button.children}
                onClick={button.onClick}
            />
        ))
    )

    const renderFooter = () => {
        if (footerRender) return (
            <div className={styles.modalFooter}>
                {footerRender()}
            </div>
        );

        if (!buttons) return null;

        const leftButtons: ModalButton[] = [];
        const rightButtons: ModalButton[] = [];

        for (const button of buttons) {
            if (button.alignLeft) {
                leftButtons.push(button);
                continue;
            }

            rightButtons.push(button);
        }

        return (
            <div className={styles.modalFooter}>
                <div className={styles.modalFooterInner}>
                    <div className={styles.left}>
                        {renderButtons(leftButtons)}
                    </div>
                    <div className={styles.right}>
                        {isLoading && <Loading className={styles.loading} />}
                        {renderButtons(rightButtons)}
                    </div>
                </div>
            </div>
        );
    }

    if (!isOpen) return null;

    return (
        <div className={styles.container}>
            <div className={styles.backdrop} onClick={handleClose} />
            <div className={classNames(styles.modal, className)} style={{ maxWidth: width || DEFAULT_WIDTH }}>

                <div className={styles.modalHeader}>
                    <div className={styles.title}>{title}</div>
                    <RxCross2 onClick={handleClose} />
                </div>

                <div className={styles.modalContent}>{children}</div>

                {renderFooter()}
            </div>
        </div>
    );
}
