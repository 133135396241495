import { useRef } from 'react';
import { AiOutlineMenu, AiFillHome } from 'react-icons/ai';
import { FaHandshake } from 'react-icons/fa';
import { RiAdminFill } from 'react-icons/ri';
import { BsFileEarmarkTextFill, BsFileEarmarkBarGraphFill } from 'react-icons/bs';

import { useOnClickOutside } from '@Hooks';
import { useAuthStore } from '@Stores';

import SidebarItem from './SidebarItem';
import styles from './Sidebar.module.scss';

type SidebarProps = {
    isOpen?: boolean;
    close: () => void;
};

const Sidebar: React.FC<SidebarProps> = ({ isOpen, close }) => {
    const appSecurityModel = useAuthStore(state => state.appSecurityModel);

    const sidebarRef = useRef(null);

    useOnClickOutside(sidebarRef, close);

    if (!isOpen || !appSecurityModel) return null;

    const canReadAdministration = appSecurityModel.hasPrincipalApplicationAdministrationRead || appSecurityModel.hasPrincipalAnyLicensorDivisionRead || appSecurityModel.hasPrincipalAnyLicenseeRead;
    return (
        <div ref={sidebarRef} className={styles.sidebar}>
            <SidebarItem
                title='Hide menu'
                className={styles.closeSidebarItem}
                Icon={AiOutlineMenu}
                onClick={close}
            />
            <SidebarItem
                text='Home'
                href='/'
                Icon={AiFillHome}
                onClick={close}
            />
            <SidebarItem
                text='Agreements'
                href='/agreements'
                Icon={FaHandshake}
                onClick={close}
            />
            <SidebarItem
                text='Reporting'
                href='/reporting'
                Icon={BsFileEarmarkTextFill}
                onClick={close}
            />
            {appSecurityModel.hasPrincipalManageAbacusAttachment &&
                <SidebarItem
                    text='ABACUS Data'
                    href='/abacus-data'
                    Icon={BsFileEarmarkTextFill}
                    onClick={close}
                />
            }
            {appSecurityModel.hasPrincipalReadInvoice &&
                <SidebarItem
                    text='Invoicing'
                    href='/invoicing'
                    Icon={BsFileEarmarkBarGraphFill}
                    onClick={close}
                />
            }
            {canReadAdministration &&
                <SidebarItem
                    text='Administration'
                    href='/administration'
                    Icon={RiAdminFill}
                    onClick={close}
                />
            }
        </div>
    );
};

export default Sidebar;