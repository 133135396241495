import React, { memo } from 'react';
import classNames from 'classnames';

import { FormFeedback } from '../FormFeedback/FormFeedback';
import styles from './Input.module.scss';

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
	invalid?: boolean;
	innerRef?: React.Ref<HTMLInputElement>;
	validationErrors?: string[];
};

export const Input = memo((props: InputProps) => {
	const { innerRef, className, invalid, validationErrors, style, value, ...rest } = props;

	const isInvalid = invalid || validationErrors?.length;

	const renderFeedback = () => {
		if (!validationErrors?.length) return null;

		return (
			<FormFeedback>
				{validationErrors.join(' ')}
			</FormFeedback>
		)
	}

	return (
		<>
			<input
				{...rest}
				ref={innerRef}
				className={classNames(styles.input, isInvalid && styles.invalid, className)}
				style={style}
				title={value?.toString()}
				value={value}
			/>
			{renderFeedback()}
		</>
	);
});
