import styles from './ImpersonationRightSidebar.module.scss';
import { useState } from 'react';

import { IImpersonationModel } from '@Stores';
import { PrincipalModel } from '@Models';
import { setLocalStorageValue } from '@Helpers';

import { Button, ButtonType, Label, PersonPicker, RightSidebar } from '@Components';


type ImpersonationRightSidebarProps = {
    isOpen: boolean;
    onClose: () => void;
}

const ImpersonationRightSidebar: React.FC<ImpersonationRightSidebarProps> = ({ isOpen, onClose }) => {
    const [impersonatedPrincipal, setImpersonatedPincipal] = useState<IImpersonationModel | null>(null);

    const handlePrincipalChange = (principal: PrincipalModel | null) => {
        if (!principal) return;

        const { id: principalId, displayName: dn, email, objectId } = principal;
        const displayName = dn.trim().length > 0 ? dn : email;
        setImpersonatedPincipal({ displayName, principalId, email, objectId });
    }

    const handleImpersonate = async () => {
        if (!impersonatedPrincipal) return;

        setLocalStorageValue('impersonatedPrincipal', impersonatedPrincipal);
        window.history.replaceState({}, document.title, window.location.pathname);
        window.location.reload();
    }

    const handleClose = () => {
        onClose();
    }

    const buttonsRender = () => {
        return (
            <div className={styles.buttons}>
                <Button name="Impersonate" type={ButtonType.Success} onClick={handleImpersonate} disabled={!impersonatedPrincipal} />
                <Button name="Cancel" type={ButtonType.Danger} onClick={handleClose} />
            </div>
        );
    }

    return (
        <RightSidebar
            isOpen={isOpen}
            title="Impersonate"
            buttonsRender={buttonsRender}
            onClose={handleClose}
        >
            <div className={styles.form}>
                <div className={styles.row}>
                    <Label text="Principal" className={styles.label} />
                    <PersonPicker value={impersonatedPrincipal?.principalId || null} onChange={handlePrincipalChange} />
                </div>
            </div>
            
        </RightSidebar>
    );
};

export default ImpersonationRightSidebar;
