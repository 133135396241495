import { useCallback } from "react";
import { ReportType, apiClient } from "@Models";
import { SingleStringPicker, SingleStringPickerProps } from "../Picker";


type RevenueTypePickerProps = SingleStringPickerProps & {
    loadDataParams?: {
        reportType?: ReportType | null;
    }
}

export const RevenueTypePicker: React.FC<RevenueTypePickerProps> = (props) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadData = useCallback(apiClient.enumRevenueTypeGet.bind(apiClient), []);

    return (
        <SingleStringPicker
            {...props}
            loadData={loadData}
        />
    );
}
