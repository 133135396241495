import { useCallback } from 'react';

import { apiClient } from '@Models';

import { SinglePickerProps, SinglePicker } from '../Picker';


type LicensorPickerProps = SinglePickerProps & {
    loadDataParams?: {
        checkManagePermission?: boolean;
    }
}

export const LicensorPicker: React.FC<LicensorPickerProps> = (props) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadData = useCallback(apiClient.licensorLookupGet.bind(apiClient), []);

    return (
        <SinglePicker
            {...props}
            loadData={loadData}
        />
    );
}
