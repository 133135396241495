import { Breadcrumbs as BreadcrumbList } from '@Components';

import styles from './Breadcrumbs.module.scss';
import { useBreadcrumb } from '@Hooks';

export const Breadcrumbs: React.FC = () => {
    useBreadcrumb('Home', '/', 0);

    return <BreadcrumbList className={styles.breadcrumbs} />;
};
