import { useMemo } from 'react';
import classNames from 'classnames';
import { AiOutlineCaretDown, AiOutlineCaretRight } from 'react-icons/ai';

import { useLoading } from '@Hooks';

import { IconButton, Loading } from '@Components';
import { TreeNode } from '../../TreeView';
import TreeViewList from '../TreeViewList/TreeViewList';
import styles from './TreeViewNode.module.scss';

type TreeViewNodeProps = {
    node: TreeNode;
    selectedNodeIds: string[];
    expandedNodeIds: string[];
    expandedAllNodes?: boolean;
    canSelectParentNode?: boolean;
    onNodeSelect?: (id: string) => void;
    onNodeDeselect?: (id: string) => void;
    onChildrenLoad?: (id: string) => Promise<void>;
    onExpand: (id: string) => void;
    onCollapse: (id: string) => void;
};

const TreeViewNode: React.FC<TreeViewNodeProps> = ({
    node, selectedNodeIds, expandedNodeIds, expandedAllNodes, canSelectParentNode,
    onNodeSelect, onNodeDeselect, onChildrenLoad, onExpand, onCollapse
}) => {
    const isSelected = useMemo(() => selectedNodeIds.includes(node.id), [node.id, selectedNodeIds]);
    const isExpanded = useMemo(() => expandedAllNodes || expandedNodeIds.includes(node.id), [node.id, expandedNodeIds, expandedAllNodes]);

    const { add: addToLoading, isLoading } = useLoading();

    const switchIsNodeSelected = () => {
        if (isSelected) {
            onNodeDeselect?.(node.id);
            return;
        }

        onNodeSelect?.(node.id);
    }

    const switchIsNodeExpanded = () => {
        if (isExpanded) {
            onCollapse(node.id);
            return;
        }

        onExpand(node.id);
    }

    const handleNodeClick = async () => {
        if (node.hasChildren) {
            if (canSelectParentNode) {
                switchIsNodeSelected();
                return;
            }

            switchIsNodeExpanded();

            if (onChildrenLoad && !isLoading) {
                if (node.children?.length) return;

                await addToLoading(() => onChildrenLoad(node.id));
            }

            return;
        }

        switchIsNodeSelected();
    }

    const handleArrowButtonClick = async () => {
        switchIsNodeExpanded();
        
        if (onChildrenLoad && !isLoading) {
            if (node.children?.length) return;

            await addToLoading(() => onChildrenLoad(node.id));
        }
    }

    const renderArrowIcon = () => (
        <div className={styles.icon}>
            <IconButton
                IconType={isExpanded ? AiOutlineCaretDown : AiOutlineCaretRight}
            />
        </div>
    );

    return (
        <>
            <div className={styles.wrapper}>
                {canSelectParentNode && node.hasChildren && (
                    <div className={styles.iconBtn} onClick={handleArrowButtonClick}>
                        {renderArrowIcon()}
                    </div>
                )}

                <div
                    className={classNames(styles.node, isSelected && styles.active)}
                    title={node.title}
                    onClick={handleNodeClick}
                >
                    {node.hasChildren && !canSelectParentNode && renderArrowIcon()}

                    <div className={styles.value}>
                        {node.value}
                    </div>

                    {isLoading && (
                        <Loading className={styles.loading} />
                    )}
                </div>
            </div>

            {!!node.children?.length && isExpanded && (
                <TreeViewList
                    nodes={node.children}
                    selectedNodeIds={selectedNodeIds}
                    expandedNodeIds={expandedNodeIds}
                    expandedAllNodes={expandedAllNodes}
                    canSelectParentNode={canSelectParentNode}
                    className={styles.childTree}
                    onNodeSelect={onNodeSelect}
                    onNodeDeselect={onNodeDeselect}
                    onNodeExpand={onExpand}
                    onNodeCollapse={onCollapse}
                    onChildrenLoad={onChildrenLoad}
                />
            )}
        </>
    );
}

export default TreeViewNode;
