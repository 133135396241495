
import classNames from 'classnames';
import { IconType } from 'react-icons/lib';

import styles from './IconButton.module.scss';

type IconButtonProps = {
    title?: string;
    className?: string;
    disabled?: boolean;
    IconType: IconType;
    onClick?: () => void;
};

export const IconButton: React.FC<IconButtonProps> = ({ title, className, disabled, IconType, onClick }) => {
    const handleClick = () => {
        if (disabled) return;

        onClick?.();
    }

    return (
        <button
            title={title}
            disabled={disabled}
            className={classNames(styles.wrapper, disabled && styles.disabled, className)}
            onClick={handleClick}
        >
            <IconType />
        </button>
    );
};
