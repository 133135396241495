import React from 'react';
import { toast } from 'react-toastify';

import { SignalREvents } from '@AppConstants';
import { signalRService } from '@Services';

import { Notification } from './Notification';


export class NotificationHandler {
    public static subscribeToNotifications() {
        signalRService.subscribe<string>(SignalREvents.success, (eventName: string, message: string) => NotificationHandler.showSuccess(message));
        signalRService.subscribe<string>(SignalREvents.warning, (eventName: string, message: string) => NotificationHandler.showWarning(message));
        signalRService.subscribe<string>(SignalREvents.error, (eventName: string, message: string) => NotificationHandler.showError(message));
        signalRService.subscribe<string>(SignalREvents.info, (eventName: string, message: string) => NotificationHandler.showInfo(message));
    }

    public static showError(message: string, title?: string, toastId?: string) {
        toast.error(this._renderNotification(message, title), { toastId });
    }

    public static showInfo(message: string, title?: string, toastId?: string) {
        toast.info(this._renderNotification(message, title), { toastId });
    }

    public static showWarning(message: string, title?: string, toastId?: string) {
        toast.warning(this._renderNotification(message, title), { toastId });
    }

    public static showSuccess(message: string, title?: string, toastId?: string) {
        toast.success(this._renderNotification(message, title), { toastId });
    }

    public static clear() {
        toast.clearWaitingQueue();
    }

    private static _renderNotification(message: string, title?: string) {
        const element = React.createElement(Notification, { title, message });
        return element;
    }
}
