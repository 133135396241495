import { useCallback, useEffect, useState, useMemo } from 'react';
import { OnChangeValue } from 'react-select';
import { AxiosPromise } from 'axios';

import { LookupModel, LookupStringValueModel } from '@Models';

import { FormFeedback } from '@Components';
import { MultiSelect, Select } from '../Basic/Select/Select';

export type PickerProps = {
    isParamsRequired?: boolean;
    loadDataParams?: object;
    disabled?: boolean;
    isClearable?: boolean;
    className?: string;
    invalid?: boolean;
    validationErrors?: string[];
    loadData?: (params?: object) => AxiosPromise<LookupModel[]>;
};

export type SinglePickerProps = PickerProps & {
    value: number | null;
    hiddenOptions?: number[];
    onChange: (selectedOption: LookupModel | null) => void;
    onLoadData?: (loadedData: LookupModel[]) => void;
}

export type MultiPickerProps = PickerProps & {
    value: number[] | null;
    onChange: (selectedOptions: LookupModel[] | null) => void;
    onLoadData?: (loadedData: LookupModel[]) => void;
}

export type SingleStringPickerProps = Omit<PickerProps, 'loadData'> & {
    value: string | null;
    hiddenOptions?: string[];
    onChange: (selectedOption: LookupStringValueModel | null) => void;
    loadData?: (params?: object) => AxiosPromise<LookupStringValueModel[]>;
    onLoadData?: (loadedData: LookupStringValueModel[]) => void;
}

export const SinglePicker: React.FC<SinglePickerProps> = ({
    value, isParamsRequired, loadDataParams, disabled, className, validationErrors, hiddenOptions,
    onChange, onLoadData, loadData, ...rest
}) => {
    const [options, setOptions] = useState<LookupModel[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const loadOptions = useCallback(async () => {
        if (!loadData) return;

        if (isParamsRequired && !loadDataParams) {
            setOptions([]);
            onChange(null);
        } else {
            setIsLoading(true);

            try {
                const { data } = await loadData(loadDataParams);
                setOptions(data);

                onLoadData?.(data);
            } finally {
                setIsLoading(false);
            }
        }

    }, [isParamsRequired, loadDataParams, onChange, loadData, onLoadData]);

    useEffect(() => {
        loadOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadDataParams]);
    
    const handleChange = (value: LookupModel | null) => {
        onChange(value);
    }

    const selectedValue = useMemo(() => {
        return options.find(option => option.value === value);
    }, [options, value]);

    const visibleOptions = useMemo(() => {
        if (!hiddenOptions) return options;

        return options.filter(option => !hiddenOptions.includes(option.value));
    }, [hiddenOptions, options]);

    return (
        <>
            <Select<LookupModel>
                options={visibleOptions}
                value={selectedValue}
                isDisabled={disabled}
                classNamePrefix={className}
                isLoading={isLoading}
                onChange={handleChange}
                {...rest}
            />
            {!!validationErrors?.length && (
                <FormFeedback>
                    {validationErrors.join(' ')}
                </FormFeedback>
            )}
        </>
    );
}

export const MultiPicker: React.FC<MultiPickerProps> = ({
    value, isParamsRequired, loadDataParams, disabled, className, validationErrors,
    onChange, loadData, onLoadData, ...rest
}) => {
    const [options, setOptions] = useState<LookupModel[]>([]);
    const [isLoading, setIsLoading] = useState(false); 

    const loadOptions = useCallback(async () => {
        if (!loadData) return;

        if (isParamsRequired && !loadDataParams) {
            setOptions([]);
            onChange(null);
        } else {
            setIsLoading(true);

            try {
                const { data } = await loadData(loadDataParams);
                setOptions(data);

                onLoadData?.(data);
            } finally {
                setIsLoading(false);
            }
        }

    }, [isParamsRequired, loadDataParams, onChange, loadData, onLoadData]);

    useEffect(() => {
        loadOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadDataParams]);

    const selectedValue = useMemo(() => {
        return options.filter(option => value?.includes(option.value))
    }, [options, value]);

    const handleChange = (value: OnChangeValue<LookupModel, true>) => {
        onChange(value as LookupModel[]);
    }

    return (
        <>
            <MultiSelect<LookupModel>
                options={options}
                value={selectedValue}
                isDisabled={disabled}
                classNamePrefix={className}
                isLoading={isLoading}
                onChange={handleChange}
                {...rest}
            />
            {!!validationErrors?.length && (
                <FormFeedback>
                    {validationErrors.join(' ')}
                </FormFeedback>
            )}
        </>
    );
}

export const SingleStringPicker: React.FC<SingleStringPickerProps> = ({
    value, isParamsRequired, loadDataParams, disabled, className, validationErrors, hiddenOptions,
    onChange, loadData, onLoadData, ...rest
}) => {
    const [options, setOptions] = useState<LookupStringValueModel[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const loadOptions = useCallback(async () => {
        if (!loadData) return;

        if (isParamsRequired && !loadDataParams) {
            setOptions([]);
            onChange(null);
        } else {
            setIsLoading(true);

            try {
                const { data } = await loadData(loadDataParams);
                setOptions(data);

                onLoadData?.(data);
            } finally {
                setIsLoading(false);
            }
        }

    }, [isParamsRequired, loadDataParams, onChange, loadData, onLoadData]);

    useEffect(() => {
        loadOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadDataParams]);

    const handleChange = (value: LookupStringValueModel | null) => {
        onChange(value);
    }

    const selectedValue = useMemo(() => {
        return options.find(option => option.value === value) || null;
    }, [options, value]);

    const visibleOptions = useMemo(() => {
        if (!hiddenOptions) return options;

        return options.filter(option => !hiddenOptions.includes(option.value));
    }, [hiddenOptions, options]);

    return (
        <>
            <Select<LookupStringValueModel>
                options={visibleOptions}
                value={selectedValue}
                isDisabled={disabled}
                classNamePrefix={className}
                isLoading={isLoading}
                onChange={handleChange}
                {...rest}
            />
            {!!validationErrors?.length && (
                <FormFeedback>
                    {validationErrors.join(' ')}
                </FormFeedback>
            )}
        </>
    );
}
