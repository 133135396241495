import { useCallback } from "react";
import { ReportType, apiClient } from "@Models";
import { SingleStringPicker, SingleStringPickerProps } from "../Picker";

type ReportTypePickerProps = SingleStringPickerProps & {
    loadDataParams?: {
        LicensorId?: number | null;
        CurrentValue?: ReportType | null;
    }
}

export const ReportTypePicker: React.FC<ReportTypePickerProps> = (props) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadData = useCallback(apiClient.enumReportTypeGet.bind(apiClient), []);

    return (
        <SingleStringPicker
            {...props}
            loadData={loadData}
        />
    );
}
