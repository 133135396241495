import { memo } from 'react';
import { BsMoonFill, BsSunFill } from 'react-icons/bs';

import { AppTheme, useAppThemeStore } from '@Stores';

import { IconButton } from '@Components';


type AppThemeButtonProps = {
    className?: string;
};

const AppThemeButton: React.FC<AppThemeButtonProps> = memo(({ className }) => {
    const { theme, setTheme } = useAppThemeStore(state => ({
        theme: state.theme,
        setTheme: state.setTheme
    }));

    const handleClick = () => {
        if (theme === AppTheme.Dark) {
            setTheme(AppTheme.Default);

            return;
        } 

        setTheme(AppTheme.Dark);
    }

    const getIcon = () => {
        switch (theme) {
            case AppTheme.Dark: 
                return BsSunFill;
            default: 
                return BsMoonFill;
        }
    }


    return (
        <IconButton
            title="Switch Theme"
            className={className}
            IconType={getIcon()}
            onClick={handleClick}
        />
    );
});

export default AppThemeButton;
