import { memo } from 'react';
import { Input, InputProps } from '../Input/Input';
import { toNumberFormat } from './numberInputHelpers';
import { FormFeedback } from '../FormFeedback/FormFeedback';

export type NumberInputProps = Omit<InputProps, 'onChange'> & {
	maxLength?: number;
	maxDecimalLength?: number;
	isNegativeValueDisabled?: boolean;
	isMaxLengthDisabled?: boolean;
	isDecimalDisabled?: boolean;
	isSelectedOnFocus?: boolean;
	onChange: (value: string) => void;
};

export const NumberInput = memo(({
	isNegativeValueDisabled, maxDecimalLength, isMaxLengthDisabled, isDecimalDisabled,
	maxLength, validationErrors, isSelectedOnFocus, onChange, ...props
}: NumberInputProps) => {

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const numberValue = event.currentTarget?.value ? toNumberFormat(String(event.currentTarget.value), {
			isDecimalDisabled, maxLength, isMaxLengthDisabled, isNegativeValueDisabled, maxDecimalLength
		}) : '';

		onChange(numberValue);
	}

	const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
		if (isSelectedOnFocus) event.currentTarget.select();

		props.onFocus?.(event);
	}

	return (
		<>
			<Input
				{...props}
				onChange={handleChange}
				onFocus={handleFocus}
			/>
			{!!validationErrors?.length && (
				<FormFeedback>
					{validationErrors.join(' ')}
				</FormFeedback>
			)}
		</>
	);
});

