import { useEffect } from 'react';

import { Breadcrumb, useBreadcrumbsStore } from '@Components';

export function useBreadcrumb(title: string, href: string, index: number) {
    useEffect(() => {
        setTimeout(() => {
            useBreadcrumbsStore.getState().addBreadcrumb({
                title,
                href,
                index
            });
        }, 0);

        return () => {
            setTimeout(() => {    
                useBreadcrumbsStore.getState().removeBreadcrumb(index);
            }, 0);
        }
    }, [title, href, index]);
}

export function useBreadcrumbList(breadcrumbs: Breadcrumb[]) {
    useEffect(() => {
        if (!breadcrumbs.length) return;

        setTimeout(() => {
            useBreadcrumbsStore.getState().addBreadcrumbList(breadcrumbs);
        }, 0);

        return () => {
            if (!breadcrumbs.length) return;

            setTimeout(() => {    
                const indexes = breadcrumbs.map(breadcrumb => breadcrumb.index);

                useBreadcrumbsStore.getState().removeBreadcrumbList(indexes);
            }, 0);
        }
    }, [breadcrumbs]);
}
