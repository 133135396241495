import { IPickerParams, PrincipalModel, apiClient } from '@Models';
import { useCallback, useState } from 'react';

const DEFAULT_PAGE_SIZE = 10;

export const usePaginatedLoading = () => {
    const [options, setOptions] = useState<PrincipalModel[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [graphNextLink, setGraphNextLink] = useState<string | undefined>();

    const loadOptions = useCallback(async (params: IPickerParams) => {
        setIsLoading(true);

        try {
            const { data } = await apiClient.principalPickerGet(params);

            const newOptions = data.principalModels.filter(principal => !options.find(option => option.objectId === principal.objectId));
            setOptions((prevOptions) => [...prevOptions, ...newOptions]);
            setGraphNextLink(data.graphNextLink)
        } finally {
            setIsLoading(false);
        }
    }, [options, setOptions, setIsLoading]);

    const loadPage = useCallback(async (params: Partial<IPickerParams>, page: number, graphNextLink?: string) => {
        params.page = page;
        params.size = DEFAULT_PAGE_SIZE;
        params.graphNextLink = graphNextLink;
        loadOptions(params);
    }, [loadOptions]);

    const loadFirstPage = (params: Partial<IPickerParams>) => loadPage(params, 0);

    const loadNextPage = useCallback(async (params: Partial<IPickerParams>) => {
        loadPage(params, page + 1, graphNextLink);
        setPage(prevpage => prevpage + 1);
    }, [page, graphNextLink, setPage, loadPage]);

    const resetOptions = useCallback(() => {
        setPage(0);
        setOptions([]);
    }, [setPage, setOptions]);

    return { options, isLoading, page, loadFirstPage, loadNextPage, resetOptions, setOptions, setPage, setGraphNextLink }
}
