import { devInfoStore } from '@Stores';

import { Environment } from '@AppConstants';
import styles from './Logo.module.scss';


type EnvironmentInfo = {
    name: string;
    color?: string;
}

const Logo: React.FC = () => {
    const getEnvironment = (): EnvironmentInfo => {
        const { appEnvironment } = devInfoStore;

        switch (appEnvironment) {
            case Environment.Development: 
                return { name: 'Development', color: 'red' };
            case Environment.Staging: 
                return { name: 'Staging', color: 'darkorange' };
            case Environment.Integration: 
                return { name: 'Integration', color: 'darkorange' };
            case Environment.Production: 
                return { name: 'Production', color: 'lightseagreen' };
            default:
                return { name: '' };
        }
    }

    const environment = getEnvironment();

    return (
        <div className={styles.logo}>
            <div className={styles.image}>
                <img src="images/logo.png" alt="ABB" />
            </div>

            <div className={styles.appInfo}>
                <div className={styles.title}>LiFe</div>
                <div className={styles.env} style={{ color: environment.color }}>
                    {environment.name}
                </div>
            </div>
        </div>
    );
};

export default Logo;
