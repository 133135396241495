import { useCallback } from "react";
import { apiClient } from "@Models";
import { AutocompleteStringPicker, AutocompleteStringPickerProps } from "../AutocompletePicker";

export const UniqueIdPicker: React.FC<AutocompleteStringPickerProps> = (props) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadData = useCallback(apiClient.securityTabsSearchSecurableObjectGet.bind(apiClient), []);

    return (
        <AutocompleteStringPicker
            {...props}
            loadData={loadData}
        />
    );
}
