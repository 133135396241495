import { memo } from 'react';
import classNames from 'classnames';

import styles from './Label.module.scss';

type LabelProps = {
    text?: string;
    className?: string;
    required?: boolean;
};

export const Label: React.FC<LabelProps> = memo(({ text, required, className }) => {
    return (
        <div className={classNames(styles.label, className)}>
            {text}{required ? <span>*</span> : ''}
        </div>
    );
});
