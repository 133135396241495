import { useRef } from 'react';
import { ModalData, useModalStore } from '@Stores';
import { ConfirmationDialog, ConfirmationDialogProps } from '@Components';


const addModal = (modal: ModalData) => useModalStore.getState().addModal(modal);
const removeModal = (modalId: number) => useModalStore.getState().removeModal(modalId);
const getModalData = () => useModalStore.getState().modalData;

export const useModal = () => {
    const resolveCallback = useRef<((value: unknown) => void) | null>(null);

    const showModal = async <P, T>(dialog: React.FunctionComponent<P>, props: P) => {
        const id = generateModalId();
        const modal: ModalData = {
            id,
            dialog: dialog as React.FunctionComponent<unknown>,
            props
        };

        return new Promise<T>(resolve => {
            resolveCallback.current = resolve as (value: unknown) => void;

            addModal(modal);
        }).finally(() => removeModal(id));
    }

    const showConfirmation = async (message?: string | JSX.Element, title?: string) => {
        const handleConfirm = () => {
            closeModal(true);
        }
    
        const handleCancel = () => {
            closeModal(false);
        }

        return showModal<ConfirmationDialogProps, boolean>(ConfirmationDialog, {
            message,
            title,
            onConfirm: handleConfirm,
            onClose: handleCancel
        });
    }

    const closeModal = (payload?: unknown) => {
        resolveCallback.current?.(payload);
    };

    return {
        showModal,
        showConfirmation,
        closeModal
    }
}

const generateModalId = () => {
    const ids = getModalData().map(modalItem => modalItem.id);

    for (let i = 1;; i++) {
        if (ids.includes(i)) continue;

        return i;
    }
}
